import DashboardIcon from "@mui/icons-material/Dashboard";
import StoreIcon from "@mui/icons-material/Store";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ClassIcon from '@mui/icons-material/Class';
import GroupsRoundedIcon from '@mui/icons-material/GroupsRounded';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded';
import CategoryRoundedIcon from '@mui/icons-material/CategoryRounded';
import RunningWithErrorsRoundedIcon from '@mui/icons-material/RunningWithErrorsRounded';
import NumbersRoundedIcon from '@mui/icons-material/NumbersRounded';
import FilePresentRoundedIcon from '@mui/icons-material/FilePresentRounded';
import AccountTreeRoundedIcon from '@mui/icons-material/AccountTreeRounded';
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';

import "./Sidebar.scss";
import { Link } from "react-router-dom";
import { Class, Group } from "@mui/icons-material";

const Sidebar = () => {
  return (
    <div className="admin-sidebar">
      <div className="admin-sidebar__top">
        <Link to="/admin" className="admin-sidebar__logo">
          Grid Admin
        </Link>
      </div>
      <div className="admin-sidebar__center">
        <ul>
          <p className="admin-sidebar__center-title">MAIN</p>

          <Link to="/admin">
            <li className="admin-sidebar__center-item">
              <DashboardIcon className="icon" />
              <span className="admin-sidebar__center-text">OverView</span>
            </li>
          </Link>

          <Link to="/admin/course">
            <li className="admin-sidebar__center-item">
              <FormatListNumberedRoundedIcon className="icon" />
              <span className="admin-sidebar__center-text">Course</span>
            </li>
          </Link>

          <Link to="/admin/faculty">
            <li className="admin-sidebar__center-item">
              <CategoryRoundedIcon className="icon" />
              <span className="admin-sidebar__center-text">Faculty</span>
            </li>
          </Link>

          <Link to="/admin/industry-group">
            <li className="admin-sidebar__center-item">
              <GroupsRoundedIcon className="icon" />
              <span className="admin-sidebar__center-text">Industry</span>
            </li>
          </Link>

          <Link to="/admin/classes">
          <li className="admin-sidebar__center-item">
            <ClassIcon className="icon" />
            <span className="admin-sidebar__center-text">Class</span>
          </li>
          </Link>

          <p className="admin-sidebar__center-title">USEFUL</p>
          <Link to="/admin/student">
            <li className="admin-sidebar__center-item">
              <Group className="icon" />
              <span className="admin-sidebar__center-text">Student</span>
            </li>
          </Link>

          <Link to="/admin/subject-debt">
            <li className="admin-sidebar__center-item">
              <RunningWithErrorsRoundedIcon className="icon" />
              <span className="admin-sidebar__center-text">Subject Debt</span>
            </li>
          </Link>

          <Link to="/admin/overtime-enrollment">
            <li className="admin-sidebar__center-item">
              <AccountTreeRoundedIcon className="icon" />
              <span className="admin-sidebar__center-text">Overtime</span>
            </li>
          </Link>

          <Link to="/admin/fee-student">
            <li className="admin-sidebar__center-item">
              <FilePresentRoundedIcon className="icon" />
              <span className="admin-sidebar__center-text">Fee Student</span>
            </li>
          </Link>

          <Link to="/admin/budget">
            <li className="admin-sidebar__center-item">
              <PaidRoundedIcon className="icon" />
              <span className="admin-sidebar__center-text">Budget</span>
            </li>
          </Link>


          <p className="admin-sidebar__center-title">SERVICE</p>
          <Link to="/admin/calculate">
          <li className="admin-sidebar__center-item">
            <CalculateRoundedIcon className="icon" />
            <span className="admin-sidebar__center-text">Calculate</span>
          </li>
          </Link>

          <Link to="/admin/scholarship">
            <li className="admin-sidebar__center-item">
              <NumbersRoundedIcon className="icon" />
              <span className="admin-sidebar__center-text">Scholarship</span>
            </li>
          </Link>

          <p className="admin-sidebar__center-title">USER</p>
          <li className="admin-sidebar__center-item">
            <AccountCircleOutlinedIcon className="icon" />
            <span className="admin-sidebar__center-text">Profile</span>
          </li>

          <li className="admin-sidebar__center-item">
            <ExitToAppIcon className="icon" />
            <span className="admin-sidebar__center-text">Logout</span>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
