import { MainComponentAdmin, ManagementListScholarship } from "../../components";

const ListScholarshipPage = () => {
    return (
        <MainComponentAdmin>
            <ManagementListScholarship />
        </MainComponentAdmin>
    );
}

export default ListScholarshipPage;