import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonFields } from "../../../shared/FormElement";
import MainToolbarAdmin from "../MainComponentAdmin/MainToolbarAdmin";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { DataGrid } from "@mui/x-data-grid";
import { industry_group_columns } from "./columns/industry_group_colums";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useDeleteIndustryGroupMutation,
  useFetchIndustriesQuery,
  useImportFileExcelMutation,
} from "../../../redux/apis/industry/industry.api";
import { ModalWarning, Skeleton } from "../../../shared/components";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleShowModalCreate,
  toggleShowModalUpdate,
} from "../../../redux/slices/industryGroup/industryGroupSlices";
import ModalUpdateFormIndustry from "./ModalUpdateFormIndustry";
import ModalCreateFormIndustry from "./ModalCreateFormIndustry";
import { useState } from "react";
import { toast } from "react-toastify";
import { FileUpload } from "../../../shared/FormElement";
import { resetFile } from "../../../redux/slices/importExcel/importExcelSlice";

const ManagementIndustryGroup = () => {
  const actionColumn = [
    {
      headerName: "Actions",
      width: 90,
      renderCell: (params) => {
        return (
          <>
            <ButtonFields
              onClick={() => handleToggleModalUpdate(params.row.id)}
              className="main-action__admin-icon"
            >
              <BorderColorIcon />
            </ButtonFields>

            <ButtonFields
              onClick={() => handleToggleModalDelete(params.id)}
              className="main-action__admin-icon"
            >
              <DeleteIcon />
            </ButtonFields>
          </>
        );
      },
    },
  ];

  const dispatch = useDispatch();
  const industryGroupState = useSelector((state) => state.industryGroupSlice);

  const [isShowModalDelete, setIsShowModalDelete] = useState(false);
  const [industryId, setIsIndustryId] = useState(null);
  const [deleteIndustryGroup, deleteIndustryGroupResults] =
    useDeleteIndustryGroupMutation();

  const handleToggleModalUpdate = (industryId) => {
    dispatch(toggleShowModalUpdate(industryId));
  };

  const handleToggleModalDelete = (industryId) => {
    setIsShowModalDelete(!isShowModalDelete);

    if (isShowModalDelete) {
      setIsIndustryId(industryId);
    }
  };

  const handleDeleteStudent = () => {
    if (industryId) {
      deleteIndustryGroup(industryId)
        .unwrap()
        .then(() => {
          toast.success(`Deleted student with id ${industryId} successfully!`);

          setIsShowModalDelete(false);
        })
        .catch((error) => toast.error(error.data.message));
    }
  };

  const importFileState = useSelector((state) => state.importFileExcel);
  const [importFile, importFileResults] = useImportFileExcelMutation();

  const handleImportFile = () => {
    const formData = new FormData();
    formData.set("file", importFileState.fileSelected);

    importFile(formData)
      .unwrap()
      .then(() => {
        toast.success(
          `Import file ${importFileState.fileSelected.name} successfully!`
        );
      })
      .catch((error) => {
        toast.error(error.data.message);
      })
      .finally(() => dispatch(resetFile()));
  };

  const fetchIndustryGroups = useFetchIndustriesQuery();
  if (fetchIndustryGroups.isFetching) {
    return <Skeleton height="7rem" times={6} />;
  } else {

    const transformedData = fetchIndustryGroups.data.map((item, index) => ({
      ...item,
      no: index + 1,
    }));

    return (
      <>
        <MainToolbarAdmin>
          <div className="d-flex align-items-center">
            <FileUpload
              onSubmit={handleImportFile}
              isLoading={importFileResults.isLoading}
            />
            <ButtonFields
              onClick={() => dispatch(toggleShowModalCreate())}
              subPrimary
              className="ml-5"
            >
              <FontAwesomeIcon
                style={{ fontSize: "1.8rem" }}
                className="mr-3"
                icon={faPlusCircle}
              />
              Create New
            </ButtonFields>
          </div>

          <div style={{ width: "45rem" }}>
            <input
              className="form-input__input"
              placeholder="Enter the industry group's name..."
            />
          </div>
        </MainToolbarAdmin>

        <div className="data-table" style={{ height: "90vh", width: "100%" }}>
          <DataGrid
            columns={industry_group_columns.concat(actionColumn)}
            rows={transformedData}
          />
        </div>

        <ModalCreateFormIndustry />
        {industryGroupState.industryGroupId && <ModalUpdateFormIndustry />}

        {/* MODAL DELETE */}
        <ModalWarning
          show={isShowModalDelete}
          onCancel={() => dispatch(handleToggleModalDelete(null))}
          headerWarning="Delete Student"
          footer={
            <div className="d-flex align-items-center justify-content-between">
              <ButtonFields
                type="button"
                onClick={() => dispatch(handleToggleModalDelete(null))}
                borderOnly
                className="seller-form__btn"
              >
                Close
              </ButtonFields>
              <ButtonFields
                onClick={handleDeleteStudent}
                type="button"
                isLoading={deleteIndustryGroupResults.isLoading}
                subPrimary
                className="seller-form__btn"
              >
                Confirm Delete
              </ButtonFields>
            </div>
          }
        >
          Are you sure you want to delete this industry?
        </ModalWarning>
        {/* MODAL DELETE */}
      </>
    );
  }
};

export default ManagementIndustryGroup;
