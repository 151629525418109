import formatMoney from "../../../../utils/format-money";

export const fee_student_columns = [
  { field: "no", headerName: "No.", width: 100 },
  { field: "studentId", headerName: "Student Id", width: 150 },
  { field: "classId", headerName: "Class Id", width: 100 },
  { field: "nameStudent", headerName: "Name", width: 300 },
  {
    field: "totalPaid",
    headerName: "Total Paid",
    width: 350,
    renderCell: (params) => {
      return <div>{formatMoney(params.row.totalPaid)}</div>;
    },
  },
];
