import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useContext } from "react";
import { AuthContext } from "./context/auth-context";

import {
  DashboardUserPage,
  PostAd,
  ChatPageUser,
  MyCartPage,
  ProfileUserPage,
  ConfirmEmailPage,
  ResetPasswordPage,
} from "./user/page";
import {
  AdminHome,
  StudentPage,
  ClassesPage,
  ScholarshipPage,
  FeeStudentPage,
  SubjectDebtPage,
  OvertimePage,
  BudgetPage,
} from "./admin/pages";
import { ErrorPage } from "./shared/pages";
import Login from "./user/components/auth/Login";
import Registration from "./user/components/auth/Registration";
import ProtectRoutes from "./routes/ProtectRoutes";
import CoursePage from "./admin/pages/CoursePage/CoursePage";
import IndustryGroupPage from "./admin/pages/IndustryGroupPage/IndustryGroupPage";
import FacultyPage from "./admin/pages/FacultyPage/FacultyPage";
import ListScholarshipPage from "./admin/pages/ListScholarshipPage/ListScholarshipPage";

function App() {
  const authContext = useContext(AuthContext);

  return (
    <Router>
      <Routes>
        {/* Routes permit all */}
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Registration />} />
        {/* Routes permit all */}
        <Route
          path="/reset-password/confirm-email"
          element={<ConfirmEmailPage />}
        />
        {/* Required token */}
        <Route path="/reset-password/:token" element={<ResetPasswordPage />} />
        {/* Required token */}
        {/* Routes logged in and all roles */}
        <Route
          element={<ProtectRoutes isAllowed={authContext.isLoggedIn} />}
        ></Route>
        <Route path="/chat-user" element={<ChatPageUser />} />

        <Route path="/dashboard-user" element={<DashboardUserPage />} />
        <Route path="/my-cart" element={<MyCartPage />} />
        <Route path="/profile-user" element={<ProfileUserPage />} />
        <Route path="/product/new" element={<PostAd />} />
        <Route path="/product/:productId/edit" element={<PostAd />} />

        {/* Routes logged in and all roles */}
        {/* Routes required Admin */}
        <Route
          element={
            <ProtectRoutes
              isAllowed={
                authContext.isLoggedIn &&
                authContext.roles.includes("ROLE_ADMIN")
              }
            />
          }
        ></Route>

        {/* Routes required Admin */}
        <Route path="*" element={<ErrorPage />} />

        {/* SCHOLARSHIP */}
        <Route path="/admin/student" element={<StudentPage />} />
        <Route path="/admin" element={<AdminHome />} />
        <Route path="/admin/classes" element={<ClassesPage />} />
        <Route path="/admin/scholarship" element={<ListScholarshipPage />} />
        <Route path="/admin/calculate" element={<ScholarshipPage />} />
        <Route path="/admin/course" element={<CoursePage />} />
        <Route path="/admin/faculty" element={<FacultyPage />} />
        <Route path="/admin/industry-group" element={<IndustryGroupPage />} />
        <Route path="/admin/fee-student" element={<FeeStudentPage />} />
        <Route path="/admin/subject-debt" element={<SubjectDebtPage />} />
        <Route path="/admin/overtime-enrollment" element={<OvertimePage />} />
        <Route path="/admin/budget" element={<BudgetPage />} />
        {/* SCHOLARSHIP */}
      </Routes>

      <ToastContainer
        position={toast.POSITION.TOP_RIGHT}
        autoClose={3000}
        icon
        className="toast__modify"
      />
    </Router>
  );
}

export default App;
