import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { API_BE_SIDE } from "../../link_api";
import pause from "../../../utils/pause";

const useStudentApis = createApi({
  reducerPath: "students",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BE_SIDE + "students",
    fetchFn: async (...args) => {
      await pause(600);
      return fetch(...args);
    },
    prepareHeaders: (headers) => {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const token = userData?.token;

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      fetchStudents: builder.query({
        providesTags: (result, error, args) => {
          return [{ type: "Students" }];
        },
        query: () => {
          return {
            method: "GET",
          };
        },
      }),
      fetchStudentById: builder.query({
        providesTags: (result, error, args) => {
          return [{ type: "Students" }];
        },
        query: (studentId) => {
          return {
            method: "GET",
            url: `${studentId}`,
          };
        },
      }),
      createStudent: builder.mutation({
        invalidatesTags: (result, error, args) => {
          return [{ type: "Students" }];
        },
        query: (data) => {
          return {
            method: "POST",
            body: data,
          };
        },
      }),
      updateStudent: builder.mutation({
        invalidatesTags: (result, error, args) => {
          return [{ type: "Students" }];
        },
        query: (payload) => {
          return {
            url: `${payload.studentId}`,
            body: payload.data,
            method: "PUT",
          };
        },
      }),
      deleteStudent: builder.mutation({
        invalidatesTags: (result, error, args) => {
          return [{ type: "Students" }];
        },
        query: (studentId) => {
          return {
            url: `${studentId}`,
            method: "DELETE",
          };
        },
      }),
      importFile: builder.mutation({
        invalidatesTags: (result, error, args) => {
          return [{ type: "Students" }];
        },
        query: (file) => {
          return {
            url: "import",
            method: "POST",
            body: file,
          };
        },
      }),
    };
  },
});

export const {
  useFetchStudentsQuery,
  useCreateStudentMutation,
  useFetchStudentByIdQuery,
  useUpdateStudentMutation,
  useImportFileMutation,
  useDeleteStudentMutation,
} = useStudentApis;
export { useStudentApis };
