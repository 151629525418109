import { createAsyncThunk } from "@reduxjs/toolkit";
import createApiClient from "../../../../shared/hooks/useAxios";

const apiClient = createApiClient();

export const fetchFaculties = createAsyncThunk(
  "faculty/fetchFaculties",
  async () => {
    const response = await apiClient.get(`/faculties`);

    return response.data;
  }
);

export const fetchFacultyBySemesterId = createAsyncThunk(
  "faculty/fetchByCourseId",
  async (courseId) => {
    const response = await apiClient.get(`/faculties/${courseId}/course`);

    return response.data;
  }
);
