import { createSlice } from "@reduxjs/toolkit";
import { fetchBudgetFromIndustryGroupId } from "../../thunks/admin/budget/budgetThunk";

const budgetSlice = createSlice({
  name: "budgetSlice",
  initialState: {
    studentScholarshipData: [],
    industryGroupData: null,
    budgetRemain: 0,
    isDisabledApprove: false,
    currentStudent: null,
    nextStudent: null,
    studentSelected: [],
  },
  reducers: {
    updateBudgetRemainWithStatusCheck: (state, action) => {
      state.studentScholarshipData = action.payload.map((item) => {
        return { ...item, checked: false };
      });
    },
    updateBudgetRemain: (state, action) => {
      state.budgetRemain = action.payload;
    },
    updateStudentScholarship: (state, action) => {
      state.studentScholarshipData = action.payload;
    },
    checkedBudgetRemain: (state, action) => {
      const { receiveValue, idStudentChecked, currentStudent, nextStudent } =
        action.payload;

      state.budgetRemain -= receiveValue;

      const studentToUpdate = state.studentScholarshipData.find(
        (student) => student.id === idStudentChecked
      );

      studentToUpdate.checked = !studentToUpdate.checked;

      if (state.budgetRemain < 0) {
        if (state.budgetRemain < receiveValue * 0.5) {
          state.isDisabledApprove = true;
        }
      }

      if (
        currentStudent.pointHb === nextStudent.pointHb &&
        currentStudent.pointRl === nextStudent.pointRl
      ) {
        state.isDisabledApprove = false;
      }

      state.studentSelected = [...state.studentSelected, currentStudent];
    },
    uncheckedBudgetRemain: (state, action) => {
      const {
        receiveValue,
        idStudentChecked,
        currentStudent,
        previousStudent,
      } = action.payload;

      state.budgetRemain += receiveValue;

      const studentToUpdate = state.studentScholarshipData.find(
        (student) => student.id === idStudentChecked
      );
      studentToUpdate.checked = !studentToUpdate.checked;

      if (state.budgetRemain > 0) {
        state.isDisabledApprove = false;
      }

      if (
        currentStudent.pointHb === previousStudent.pointHb &&
        currentStudent.pointRl === previousStudent.pointRl
      ) {
        state.isDisabledApprove = false;
      }

      state.studentSelected = state.studentSelected.filter(
        (studentItem) => studentItem.id !== currentStudent.id
      );
    },
    updateStatusDisable: (state, action) => {
      state.isDisabledApprove = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBudgetFromIndustryGroupId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchBudgetFromIndustryGroupId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchBudgetFromIndustryGroupId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.industryGroupData = action.payload;
      });
  },
});

export const {
  uncheckedBudgetRemain,
  checkedBudgetRemain,
  updateBudgetRemainWithStatusCheck,
  updateBudgetRemain,
  updateStatusDisable,
} = budgetSlice.actions;
export const budgetReducer = budgetSlice.reducer;
