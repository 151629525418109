import formatMoney from "../../../../utils/format-money";

export const industry_group_columns = [
  { field: "no", headerName: "No.", width: 100 },
  { field: "id", headerName: "ID", width: 200 },
  { field: "name", headerName: "Name", width: 300 },
  { field: "courseId", headerName: "Course ID", width: 100 },
  { field: "facultyId", headerName: "Faculty ID", width: 100 },
  {
    field: "previousBudgetRemain",
    headerName: "Previous Budget Remain",
    width: 250,
    renderCell: (params) => {
      return <div>{formatMoney(params.row.previousBudgetRemain)}</div>;
    },
  },
];