import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ButtonFields, InputFields } from "../../../shared/FormElement";
import { Modal } from "../../../shared/components";
import { useFetchClassesQuery } from "../../../redux/apis/classes/classes.api";
import { useEffect } from "react";
import {
  useFetchFacultyByIdQuery,
  useUpdateFacultyMutation,
} from "../../../redux/apis/faculty/faculty.api";

const ModalUpdateFormFaculty = ({ show = false, facultyId, toggleModal }) => {
  const methods = useForm({ mode: "all" });

  const fetchFacultyById = useFetchFacultyByIdQuery(facultyId);
  const doFetchClassesState = useFetchClassesQuery();
  const [doUpdateCourse, doUpdateCourseResults] = useUpdateFacultyMutation();

  useEffect(() => {
    if (!!facultyId && fetchFacultyById.data) {
      methods.reset(fetchFacultyById.data);
    }
  }, [facultyId, fetchFacultyById.data, methods]);

  const onSubmit = (data) => {
    doUpdateCourse({ id: facultyId, data })
      .unwrap()
      .then(() => {
        toast.success("Update faculty successfully!", {
          autoClose: 2000,
        });
        toggleModal();
      })
      .catch((error) => toast.error(error.data.message));
  };

  return (
    <>
      {doFetchClassesState.data && (
        <FormProvider {...methods}>
          <Modal
            onSubmit={methods.handleSubmit(onSubmit)}
            show={show}
            onCancel={toggleModal}
            footer={
              <div className="d-flex flex-row-reverse aligns-item">
                <ButtonFields
                  type="submit"
                  primary
                  className="ml-5"
                  isLoading={doUpdateCourseResults.isLoading}
                >
                  SUBMIT
                </ButtonFields>
                <ButtonFields type="button" onClick={toggleModal} borderOnly>
                  BACK
                </ButtonFields>
              </div>
            }
          >
            <InputFields
              fieldName="id"
              classNameForm="mr-5"
              type="text"
              label="ID (*)"
              placeholder="Enter Id"
              htmlFor="id"
            />

            <InputFields
              fieldName="name"
              type="text"
              label="Name (*)"
              placeholder="Enter Course Name"
              htmlFor="name"
            />
          </Modal>
        </FormProvider>
      )}
    </>
  );
};

export default ModalUpdateFormFaculty;
