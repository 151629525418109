import MainToolbarAdmin from "../MainComponentAdmin/MainToolbarAdmin";
import { CustomToolbar, Skeleton } from "../../../shared/components";
import { ButtonFields, SelectFields } from "../../../shared/FormElement";
import { FormProvider, useForm } from "react-hook-form";
import React, { memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { list_scholarship_columns } from "./columns/list_scholarship_columns";
import { DataGrid } from "@mui/x-data-grid";
import useThunk from "../../../shared/hooks/useThunk";
import { fetchScholarships } from "../../../redux/thunks/admin/calculateScholarship/calculateScholarshipThunk";
import { fetchFaculties } from "../../../redux/thunks/admin/faculty/facultyThunk";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { useGridRootProps } from '@mui/x-data-grid-pro';

const ManagementListScholarship = () => {
  const rootProps = useGridRootProps();

  const methods = useForm();

  const facultySelected = methods.watch("facultyId");
  const scholarshipState = useSelector((state) => state.scholarshipSlice);

  const [doFetchFaculties] = useThunk(fetchFaculties);
  const [doFetchListScholarships, doFetchListScholarshipsLoading] =
    useThunk(fetchScholarships);

  const onSubmit = (data) => {
    if (!facultySelected) {
      toast.error("You must choose faculty");
      return;
    }

    doFetchListScholarships(facultySelected);
  };

  useEffect(() => {
    doFetchFaculties();
  }, [doFetchFaculties]);

  if (doFetchListScholarshipsLoading) {
    return <Skeleton times={6} height={"7rem"} />;
  } else {
    const transformedData =
      scholarshipState.scholarshipsData ??
      [].map((item, index) => ({
        ...item,
        no: index + 1,
      }));
    return (
      <>
        <MainToolbarAdmin>
          <FormProvider {...methods}>
            <form
              style={{ width: "100%" }}
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <div className="row w-100">
                {scholarshipState.facultiesData && (
                  <div className="col-3">
                    <SelectFields
                      fieldName="facultyId"
                      htmlFor="faculty"
                      label="Faculty (*)"
                      initialValue=""
                    >
                      <option value="">Choose your faculty</option>

                      {scholarshipState.facultiesData &&
                        scholarshipState.facultiesData.map((faculty) => (
                          <option key={faculty.id} value={faculty.id}>
                            {faculty.name}
                          </option>
                        ))}
                    </SelectFields>
                  </div>
                )}

                <ButtonFields
                  style={{ height: "3.5rem" }}
                  type="submit"
                  primary
                  className="m-3"
                  onSubmit
                >
                  VIEW DATA
                </ButtonFields>
              </div>
            </form>
          </FormProvider>
        </MainToolbarAdmin>

        <div className="data-table" style={{ height: "90vh", width: "100%" }}>
          <DataGridPremium
            rows={transformedData}
            columns={list_scholarship_columns}
            slots={{
              toolbar: CustomToolbar,
            }}
            // components={{
            //   Toolbar: CustomToolbar,
            // }}
          />
        </div>
      </>
    );
  }
};

export default memo(ManagementListScholarship);
