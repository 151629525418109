import { createSlice } from "@reduxjs/toolkit";

const semesterSlices = createSlice({
  name: "semesterSlice",
  initialState: {
    isShowModalCreate: false,
    isShowModalUpdate: false,
    idSemester: null,
    isShowModalDelete: false,
  },
  reducers: {
    toggleShowModalCreate: (state) => {
      state.isShowModalCreate = !state.isShowModalCreate;
    },
    toggleShowModalUpdate: (state, action) => {
      state.isShowModalUpdate = !state.isShowModalUpdate;

      if (state.isShowModalUpdate) {
        state.idSemester = action.payload;
      }
    },
    toggleShowModalDelete: (state, action) => {
      console.log("action.payload", action.payload);
      state.isShowModalDelete = !state.isShowModalDelete;

      if (state.isShowModalUpdate) {
        state.idSemester = action.payload;
      }
    },
  },
});

export const {
  toggleShowModalCreate,
  toggleShowModalUpdate,
  toggleShowModalDelete,
} = semesterSlices.actions;
export const semesterReducer = semesterSlices.reducer;
