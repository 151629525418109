import { createSlice } from "@reduxjs/toolkit";
import {
  fetchFaculties,
  fetchFacultyBySemesterId,
} from "../../thunks/admin/faculty/facultyThunk";
import { fetchIndustryGroupByFacultyId } from "../../thunks/admin/industryGroup/industryGroupThunk";
import {
  fetchIndustryGroupByCourseIdAndFacultyId,
  fetchScholarships,
  fetchStudentListByClassId,
  fetchStudentsByIndustryGroupIdAndStatus,
  saveScholarshipStudent,
} from "../../thunks/admin/calculateScholarship/calculateScholarshipThunk";
import { fetchCourses } from "../../thunks/admin/cource/courceThunk";

const initialState = {
  courseData: null,
  facultyData: null,
  facultiesData: [],
  studentScholarshipData: [],
  industryGroupData: null,
  isLoading: false,
  error: null,
  scholarshipData: null,
  studentListsData: null,
  isShowAdditionData: false,
  scholarshipsData: [],
};

const scholarshipSlice = createSlice({
  name: "scholarshipSlice",
  initialState,
  reducers: {
    toggleShowAdditionData: (state) => {
      state.isShowAdditionData = !state.isShowAdditionData;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourses.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCourses.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchCourses.fulfilled, (state, action) => {
        state.isLoading = false;
        state.courseData = action.payload;
      });

    builder
      .addCase(fetchFacultyBySemesterId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFacultyBySemesterId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchFacultyBySemesterId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.facultyData = action.payload;
      });

    builder
      .addCase(fetchFaculties.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchFaculties.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchFaculties.fulfilled, (state, action) => {
        state.isLoading = false;
        state.facultiesData = action.payload;
      });

    builder
      .addCase(fetchIndustryGroupByFacultyId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchIndustryGroupByFacultyId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchIndustryGroupByFacultyId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.industryGroupData = action.payload;
      });

    builder
      .addCase(fetchIndustryGroupByCourseIdAndFacultyId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchIndustryGroupByCourseIdAndFacultyId.rejected,
        (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
        }
      )
      .addCase(
        fetchIndustryGroupByCourseIdAndFacultyId.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.scholarshipData = action.payload;
        }
      );

    builder
      .addCase(fetchStudentListByClassId.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchStudentListByClassId.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchStudentListByClassId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.studentListsData = action.payload;
      });

    builder
      .addCase(fetchStudentsByIndustryGroupIdAndStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchStudentsByIndustryGroupIdAndStatus.rejected,
        (state, action) => {
          state.isLoading = false;
          state.error = action.payload;
        }
      )
      .addCase(
        fetchStudentsByIndustryGroupIdAndStatus.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.studentScholarshipData = action.payload;
        }
      );

    builder
      .addCase(saveScholarshipStudent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveScholarshipStudent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(saveScholarshipStudent.fulfilled, (state, action) => {
        state.isLoading = false;
      });

    builder
      .addCase(fetchScholarships.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchScholarships.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      })
      .addCase(fetchScholarships.fulfilled, (state, action) => {
        state.isLoading = false;
        state.scholarshipsData = action.payload;
      });
  },
});

export const { toggleShowAdditionData } = scholarshipSlice.actions;
export const scholarshipReducer = scholarshipSlice.reducer;
