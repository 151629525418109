import { MainComponentAdmin } from "../../components";
import ManagementOvertime from "../../components/ManagementOvertime/ManagementOvertime";

const OvertimePage = () => {
  return (
    <MainComponentAdmin>
      <ManagementOvertime />
    </MainComponentAdmin>
  );
};

export default OvertimePage;
