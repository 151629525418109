import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { enableMapSet } from "immer";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { userPasswordApis } from "./apis/user/password/user-password.api";
import { authApis } from "./apis/auth/authApis";
import { addressReducer } from "./slices/user/address/addressSlice";
import { userProfileApis } from "./apis/user/profile/user-profile.api";
import { userLocationsApi } from "./apis/user/location/user-locations.api";
import { locationReducer } from "./slices/user/location/locationSlice";
import { useSellerRegisterApi } from "./apis/user/seller/seller-register.api";
import { sellerReducer } from "./slices/seller/sellerSlice";
import { fetchAllReducer } from "./slices/admin/fetchAll/fetchAllSlice";
import { useSellerProductApis } from "./apis/seller/product/seller-product.api";
import { addProductReducer } from "./slices/seller/add-product/addProductSlice";
import { useProductStyleApis } from "./apis/seller/product-style/product-style.api";
import { multipleSelectReducer } from "./slices/FormElement/MultipleSelect/multipleSelectSlice";
import { productCategorizationReducer } from "./slices/seller/product-categorization/productCategorizationSlice";
import { inventoryReducer } from "./slices/seller/inventory/inventorySlice";
import { myAdsReducers } from "./slices/seller/myAds/myAdsSlice";
import { useClassesApis } from "./apis/classes/classes.api";
import { useStudentApis } from "./apis/student/student.api";
import { addStudentReducer } from "./slices/student/addStudent/addStudentSlices";
import { useIndustryApis } from "./apis/industry/industry.api";
import { classesReducer } from "./slices/classes/classesSlice";
import { useSemesterApis } from "./apis/semester/semesters.api";
import { semesterReducer } from "./slices/semester/semesterSlices";
import { scholarshipReducer } from "./slices/scholarship/sholarshipSlice";
import { useCourseApis } from "./apis/course/course.api";
import { industryGroupReducer } from "./slices/industryGroup/industryGroupSlices";
import { importFileExcelReducers } from "./slices/importExcel/importExcelSlice";
import { useFacultiesApis } from "./apis/faculty/faculty.api";
import { useFeeStudentApis } from "./apis/feeStudent/feeStudent.api";
import { useSubjectDebtApis } from "./apis/subjectDebt/subjectDebt.api";
import { useOvertimeApis } from "./apis/overtime/overtime.api";
import { useBudgetApis } from "./apis/budget/budget.api";
import { budgetReducer } from "./slices/budget/budgetSlice";

enableMapSet();

const store = configureStore({
  reducer: {
    fetchAll: fetchAllReducer,
    address: addressReducer,
    location: locationReducer,
    seller: sellerReducer,
    addProduct: addProductReducer,
    multipleSelect: multipleSelectReducer,
    productCategorization: productCategorizationReducer,
    inventory: inventoryReducer,
    myAds: myAdsReducers,
    addStudent: addStudentReducer,
    classesSlice: classesReducer,
    semesterSlice: semesterReducer,
    scholarshipSlice: scholarshipReducer,
    industryGroupSlice: industryGroupReducer,
    importFileExcel: importFileExcelReducers,
    budgetSlice: budgetReducer,
    [authApis.reducerPath]: authApis.reducer,
    [userPasswordApis.reducerPath]: userPasswordApis.reducer,
    [userProfileApis.reducerPath]: userProfileApis.reducer,
    [userLocationsApi.reducerPath]: userLocationsApi.reducer,
    [useSellerRegisterApi.reducerPath]: useSellerRegisterApi.reducer,
    [useSellerProductApis.reducerPath]: useSellerProductApis.reducer,
    [useClassesApis.reducerPath]: useClassesApis.reducer,
    [useStudentApis.reducerPath]: useStudentApis.reducer,
    [useIndustryApis.reducerPath]: useIndustryApis.reducer,
    [useSemesterApis.reducerPath]: useSemesterApis.reducer,
    [useCourseApis.reducerPath]: useCourseApis.reducer,
    [useFacultiesApis.reducerPath]: useFacultiesApis.reducer,
    [useFeeStudentApis.reducerPath]: useFeeStudentApis.reducer,
    [useSubjectDebtApis.reducerPath]: useSubjectDebtApis.reducer,
    [useOvertimeApis.reducerPath]: useOvertimeApis.reducer,
    [useBudgetApis.reducerPath]: useBudgetApis.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({ serializableCheck: false })
      .concat(authApis.middleware)
      .concat(userPasswordApis.middleware)
      .concat(userProfileApis.middleware)
      .concat(userLocationsApi.middleware)
      .concat(useSellerRegisterApi.middleware)
      .concat(useSellerProductApis.middleware)
      .concat(useProductStyleApis.middleware)
      .concat(useProductStyleApis.middleware)
      .concat(useStudentApis.middleware)
      .concat(useClassesApis.middleware)
      .concat(useIndustryApis.middleware)
      .concat(useCourseApis.middleware)
      .concat(useFacultiesApis.middleware)
      .concat(useFeeStudentApis.middleware)
      .concat(useSemesterApis.middleware)
      .concat(useSubjectDebtApis.middleware)
      .concat(useOvertimeApis.middleware)
      .concat(useBudgetApis.middleware);
  },
});

setupListeners(store.dispatch);

export default store;
