import { createSlice } from "@reduxjs/toolkit";

const importFileExcel = createSlice({
  name: "importFileExcel",
  initialState: {
    fileSelected: null,
  },
  reducers: {
    onPickerFile: (state, action) => {
      state.fileSelected = action.payload;
    },
    resetFile: (state) => {
      state.fileSelected = null;
    },
  },
});

export const { onPickerFile, resetFile } = importFileExcel.actions;
export const importFileExcelReducers = importFileExcel.reducer;
