import { FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import {
  ButtonFields,
  InputFields,
  SelectFields,
} from "../../../shared/FormElement";
import { toggleShowModalUpdate } from "../../../redux/slices/student/addStudent/addStudentSlices";
import { Modal } from "../../../shared/components";
import { useDispatch, useSelector } from "react-redux";
import { useFetchClassesQuery } from "../../../redux/apis/classes/classes.api";
import { formatDate } from "../../../utils/format-date.js";
import { useEffect } from "react";
import {
  useFetchStudentByIdQuery,
  useUpdateStudentMutation,
} from "../../../redux/apis/student/student.api";

const ModalUpdateFormStudent = () => {
  const methods = useForm({ mode: "all" });

  const dispatch = useDispatch();
  const addProductState = useSelector((state) => state.addStudent);

  const doFetchStudentById = useFetchStudentByIdQuery(
    addProductState.studentId
  );
  const doFetchClassesState = useFetchClassesQuery();
  const [doUpdateStudent, doUpdateStudentResults] = useUpdateStudentMutation();

  useEffect(() => {
    if (addProductState.studentId && doFetchStudentById.data) {
      methods.reset(doFetchStudentById.data);
      methods.setValue(
        "dateBirth",
        doFetchStudentById.data.dateBirth.split("T")[0]
      );
    }
  }, [addProductState.studentId, doFetchStudentById.data, methods]);

  const onSubmit = (data) => {
    const formattedData = {
      ...data,
      dateBirth: formatDate(data.dateBirth),
    };
    doUpdateStudent({
      data: formattedData,
      studentId: addProductState.studentId,
    })
      .unwrap()
      .then(() => {
        toast.success("Update student successfully!", {
          autoClose: 2000,
        });
        dispatch(toggleShowModalUpdate());
      })
      .catch((error) => toast.error(error.data.message));
  };

  return (
    <>
      {doFetchClassesState.data && (
        <FormProvider {...methods}>
          <Modal
            onSubmit={methods.handleSubmit(onSubmit)}
            show={addProductState.isShowModalUpdate}
            onCancel={() => dispatch(toggleShowModalUpdate())}
            footer={
              <div className="d-flex flex-row-reverse aligns-item">
                <ButtonFields
                  type="submit"
                  primary
                  className="ml-5"
                  isLoading={doUpdateStudentResults.isLoading}
                >
                  SUBMIT
                </ButtonFields>
                <ButtonFields
                  type="button"
                  onClick={() => dispatch(toggleShowModalUpdate())}
                  borderOnly
                >
                  BACK
                </ButtonFields>
              </div>
            }
          >
            <InputFields
              fieldName="id"
              classNameForm="mr-5"
              type="text"
              placeholder="Enter ID"
              label="ID (*)"
              htmlFor="name"
            />

            <InputFields
              fieldName="name"
              type="text"
              placeholder="Enter student name"
              label="Student Name (*)"
              htmlFor="name"
            />

            <SelectFields
              fieldName="classId"
              htmlFor="classes"
              label="Classes (*)"
              initialValue=""
            >
              <option value="">Choose your class</option>

              {doFetchClassesState.data.map((classValue) => (
                <option key={classValue.id} value={classValue.id}>
                  {classValue.name}
                </option>
              ))}
            </SelectFields>

            <InputFields
              fieldName="dateBirth"
              type="date"
              label="Date of birth (*)"
              htmlFor="dateBirth"
            />

            <SelectFields fieldName="gender" label="Gender (*)" initialValue="">
              <option value="">Choose your gender</option>
              <option value={0}>MALE</option>
              <option value={1}>FEMALE</option>
            </SelectFields>
          </Modal>
        </FormProvider>
      )}
    </>
  );
};

export default ModalUpdateFormStudent;
