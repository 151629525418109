export const formatDate = (dateString) => {
  const dateObj = new Date(dateString);
  const month = (dateObj.getMonth() + 1).toString().padStart(2, "0");
  const day = dateObj.getDate().toString().padStart(2, "0");
  const year = dateObj.getFullYear().toString();

  return `${month}/${day}/${year}`;
};

export const formatDateTime = (data) => {
  const year = data.getFullYear();
  const month = (data.getMonth() + 1).toString().padStart(2, "0");
  const day = data.getDate().toString().padStart(2, "0");


  return `${month}/${day}/${year}`;
};
