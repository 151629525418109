import formatMoney from "../../../../utils/format-money";

export const budget_columns = [
  { field: "no", headerName: "No.", width: 50 },
  { field: "id", headerName: "ID", width: 200 },
  { field: "name", headerName: "Name", width: 300 },
  { field: "courseId", headerName: "Course ID", width: 100 },
  { field: "facultyId", headerName: "Faculty ID", width: 100 },
  {
    field: "previousBudgetRemain",
    headerName: "Previous Budget Remain",
    width: 250,
    renderCell: (params) => {
      return <div>{formatMoney(params.row.previousBudgetRemain)}</div>;
    },
  },
  {
    field: "totalTuitionFee",
    headerName: "Total Tuition Fee",
    width: 250,
    renderCell: (params) => {
      return <div>{formatMoney(params.row.totalTuitionFee)}</div>;
    },
  },
  {
    field: "tuitionFeeExtracted",
    headerName: "Tuition Fee Extracted",
    width: 250,
    renderCell: (params) => {
      return <div>{formatMoney(params.row.tuitionFeeExtracted)}</div>;
    },
  },
  {
    field: "tuitionFeeAllocated",
    headerName: "Tuition Fee Allocated",
    width: 250,
    renderCell: (params) => {
      return <div>{formatMoney(params.row.tuitionFeeAllocated)}</div>;
    },
  },
  {
    field: "remainingBudget",
    headerName: "Remaining Budget",
    width: 250,
    renderCell: (params) => {
      return <div>{formatMoney(params.row.remainingBudget)}</div>;
    },
  },
];