import { FormProvider, useForm } from "react-hook-form";
import { Modal } from "../../../shared/components";
import { useDispatch, useSelector } from "react-redux";
import {
  ButtonFields,
  InputFields,
  SelectFields,
} from "../../../shared/FormElement";
import { toggleShowModalCreate } from "../../../redux/slices/classes/classesSlice";
import { VALIDATOR_REQUIRED } from "../../../shared/util/validators";
import { useFetchIndustriesQuery } from "../../../redux/apis/industry/industry.api";
import { useCreateClassesMutation } from "../../../redux/apis/classes/classes.api";
import { toast } from "react-toastify";

const ModalCreateFormClasses = () => {
  const methods = useForm({ mode: "onChange" });

  const classesState = useSelector((state) => state.classesSlice);
  const dispatch = useDispatch();

  const fetchAllIndustries = useFetchIndustriesQuery();
  const [doCreateClass, doCreateClassResults] = useCreateClassesMutation();

  const onSubmit = (data) => {
    doCreateClass(data)
      .unwrap()
      .then(() => {
        toast.success("Create new class successfully!", { autoClose: 2000 });
        dispatch(toggleShowModalCreate());
      })
      .catch((error) => {
        toast.error(error.data.message);
      });
  };

  return (
    <>
      {!fetchAllIndustries.isLoading && (
        <FormProvider {...methods}>
          <Modal
            onSubmit={methods.handleSubmit(onSubmit)}
            show={classesState.isShowModalCreate}
            onCancel={() => dispatch(toggleShowModalCreate())}
            footer={
              <div className="d-flex flex-row-reverse aligns-item">
                <ButtonFields
                  type="submit"
                  primary
                  className="ml-5"
                  isLoading={doCreateClassResults.isLoading}
                >
                  SUBMIT
                </ButtonFields>
                <ButtonFields
                  type="button"
                  onClick={() => dispatch(toggleShowModalCreate())}
                  borderOnly
                >
                  BACK
                </ButtonFields>
              </div>
            }
          >
            <InputFields
              fieldName="id"
              classNameForm="mr-5"
              validators={[VALIDATOR_REQUIRED("Id class cannot be empty")]}
              type="text"
              placeholder="Enter ID"
              label="ID (*)"
              htmlFor="name"
            />

            <InputFields
              fieldName="name"
              validators={[VALIDATOR_REQUIRED("Class Name cannot be empty")]}
              type="text"
              placeholder="Enter student name"
              label="Class Name (*)"
              htmlFor="name"
            />

            <SelectFields
              fieldName="industryGroupId"
              htmlFor="industry"
              label="Industry (*)"
              initialValue=""
            >
              <option value="">Choose your industry</option>

              {fetchAllIndustries.data &&
                fetchAllIndustries.data.map((industry) => (
                  <option key={industry.id} value={industry.id}>
                    {industry.name}
                  </option>
                ))}
            </SelectFields>
          </Modal>
        </FormProvider>
      )}
    </>
  );
};

export default ModalCreateFormClasses;
