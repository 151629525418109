import { createAsyncThunk } from "@reduxjs/toolkit";
import createApiClient from "../../../../shared/hooks/useAxios";

const apiClient = createApiClient();

export const fetchIndustryGroupByFacultyId = createAsyncThunk(
  "industryGroup/fetchByFacultyId",
  async (facultyId) => {
    const response = await apiClient.get(
      `/industry-group/${facultyId}/faculty`
    );

    return response.data;
  }
);
