import { useCallback, useState } from "react";

/*
 * This hook is used to display elements corresponding to capacityPage
 */

const usePaginate = () => {
  const [data, setData] = useState([]);

  const updateData = useCallback((newData) => {
    setData(newData);
  }, []);

  const paginate = useCallback(
    (curPage = 1, capacityPage) => {
      const indexOfLastPost = curPage * capacityPage;
      const indexOfFirstPost = indexOfLastPost - capacityPage;
      const storage = data.slice(indexOfFirstPost, indexOfLastPost);

      return storage;
    },
    [data]
  );

  return { paginate, updateData };
};

export default usePaginate;
