import { MainComponentAdmin } from "../../components";
import ManagementFeeStudents from "../../components/ManagementFeeStudents/ManagementFeeStudents";

const FeeStudentPage = () => {
  return (
    <MainComponentAdmin>
      <ManagementFeeStudents />
    </MainComponentAdmin>
  );
};

export default FeeStudentPage;
