import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid-premium';

const CustomToolbar = () => {
  return (
    <>
      <GridToolbarContainer className="d-flex align-items-center justify-content-between">
        <GridToolbarExport />
        {/* <GridToolbarFilterButton />
        <GridToolbarQuickFilter /> */}
      </GridToolbarContainer>
    </>
  );
};

export default CustomToolbar;
