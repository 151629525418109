import { createAsyncThunk } from "@reduxjs/toolkit";
import createApiClient from "../../../../shared/hooks/useAxios";

const apiClient = createApiClient();

export const fetchCourses = createAsyncThunk(
  "faculty/fetchCourse",
  async () => {
    const response = await apiClient.get(`/courses`);

    return response.data;
  }
);
