import { MainComponentAdmin, ManagementScholarship } from "../../components";

const ScholarshipPage = () => {
    return (
        <MainComponentAdmin>
            <ManagementScholarship />
        </MainComponentAdmin>
    );
}

export default ScholarshipPage;