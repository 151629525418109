import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { API_BE_SIDE } from "../../link_api";
import pause from "../../../utils/pause";

const useClassesApis = createApi({
  reducerPath: "classesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BE_SIDE + "classes",
    fetchFn: async (...args) => {
      await pause(600);
      return fetch(...args);
    },
    prepareHeaders: (headers) => {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const token = userData?.token;

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      fetchClasses: builder.query({
        providesTags: (result, error, args) => {
          const tags = result.map((classes) => {
            return { type: "Classes", id: classes.id };
          });

          tags.push({ type: "ClassesIndustry" });
          return tags;
        },
        query: () => {
          return {
            method: "GET",
          };
        },
      }),
      fetchClassById: builder.query({
        providesTags: (result, error, args) => {
          return [{ type: "Classes", id: result.id }];
        },
        query: (classId) => {
          return {
            method: "GET",
            url: `${classId}`,
          };
        },
      }),
      createClasses: builder.mutation({
        invalidatesTags: (result, error, args) => {
          return [{ type: "ClassesIndustry" }];
        },
        query: (data) => {
          return {
            method: "POST",
            body: data,
          };
        },
      }),
      updateClass: builder.mutation({
        invalidatesTags: (result, error, args) => {
          return [{ type: "ClassesIndustry" }];
        },
        query: (payload) => {
          return {
            url: `${payload.id}`,
            method: "PUT",
            body: payload.data,
          };
        },
      }),
      deleteClass: builder.mutation({
        invalidatesTags: (result, error, args) => {
          return [{ type: "ClassesIndustry" }];
        },
        query: (classId) => {
          return {
            url: `${classId}`,
            method: "DELETE",
          };
        },
      }),
      importFileExcel: builder.mutation({
        invalidatesTags: (result, error, args) => {
          return [{ type: "ClassesIndustry" }];
        },
        query: (file) => {
          return {
            url: "/import",
            method: "POST",
            body: file,
          };
        },
      }),
    };
  },
});

export const {
  useFetchClassesQuery,
  useFetchClassByIdQuery,
  useCreateClassesMutation,
  useUpdateClassMutation,
  useDeleteClassMutation,
  useImportFileExcelMutation,
} = useClassesApis;
export { useClassesApis };
