export const classes_columns = [
  { field: "no", headerName: "No.", width: 100 },
  { field: "id", headerName: "ID", width: 130 },
  { field: "name", headerName: "Class Name", width: 220 },
  {
    field: "industryGroupId",
    headerName: "ID Industry",
    width: 220,
  },
  {
    field: "industryGroupName",
    headerName: "Industry Name",
    width: 300,
  },
];
