import { MainComponentAdmin, ManagementClasses } from "../../components";

const ClassesPage = () => {
    return (
        <MainComponentAdmin>
            <ManagementClasses />
        </MainComponentAdmin>
    );
};

export default ClassesPage;
