import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonFields } from "../../../shared/FormElement";
import MainToolbarAdmin from "../MainComponentAdmin/MainToolbarAdmin";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { DataGrid } from "@mui/x-data-grid";
import { budget_columns } from "./columns/budget_columns";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  useFetchBudgetsQuery,
} from "../../../redux/apis/budget/budget.api";
import { ModalWarning, Skeleton } from "../../../shared/components";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";

const ManagementBudgets = () => {
  
  const fetchBudgets = useFetchBudgetsQuery();
  const dispatch = useDispatch();

  if (fetchBudgets.isFetching) {
    return <Skeleton height="7rem" times={6} />;
  } else {

    const transformedData = fetchBudgets.data.map((item, index) => ({
      ...item,
      no: index + 1,
    }));

    return (
      <>
      {!!fetchBudgets.data && (
        <div className="data-table" style={{ height: "90vh", width: "100%" }}>
          <DataGrid
            columns={budget_columns}
            rows={transformedData}
          />
        </div>
      )}
    </>
    )
  }
};

export default ManagementBudgets;
