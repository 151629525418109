import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import {
  useFetchFeeStudentsQuery,
  useImportFileExcelMutation,
} from "../../../redux/apis/feeStudent/feeStudent.api";
import { toast } from "react-toastify";
import { resetFile } from "../../../redux/slices/importExcel/importExcelSlice";
import MainToolbarAdmin from "../MainComponentAdmin/MainToolbarAdmin";
import ButtonFields from "../../../shared/FormElement/ButtonFields/ButtonFields";
import { FileUpload } from "../../../shared/FormElement";
import { DataGrid } from "@mui/x-data-grid";
import { Skeleton } from "@mui/material";
import { fee_student_columns } from "./columns/fee_student_column";

const ManagementFeeStudents = () => {
  const fetchFeeStudents = useFetchFeeStudentsQuery();
  const dispatch = useDispatch();
  const importFileState = useSelector((state) => state.importFileExcel);
  const [importFile, importFileResults] = useImportFileExcelMutation();

  const handleImportFile = () => {
    const formData = new FormData();
    formData.set("file", importFileState.fileSelected);

    importFile(formData)
      .unwrap()
      .then(() => {
        toast.success(
          `Import file ${importFileState.fileSelected.name} successfully!`
        );
      })
      .catch((error) => {
        toast.error(error.data.message);
      })
      .finally(() => dispatch(resetFile()));
  };

  if (fetchFeeStudents.isFetching) {
    return <Skeleton times={6} height={"7rem"} />;
  } else {

    const transformedData = fetchFeeStudents.data.map((item, index) => ({
      ...item,
      no: index + 1,
    }));

    return (
      <>
        <MainToolbarAdmin>
          <div className="d-flex align-items-center">
            <FileUpload
              onSubmit={handleImportFile}
              isLoading={importFileResults.isLoading}
            />
            <ButtonFields
              //   onClick={handleToggleModalCreate}
              subPrimary
              className="ml-5"
            >
              <FontAwesomeIcon
                style={{ fontSize: "1.8rem" }}
                className="mr-3"
                icon={faPlusCircle}
              />
              Create New
            </ButtonFields>
          </div>
        </MainToolbarAdmin>

        {!!fetchFeeStudents.data && (
          <div className="data-table" style={{ height: "90vh", width: "100%" }}>
            <DataGrid
              columns={fee_student_columns}
              rows={transformedData}
            />
          </div>
        )}
      </>
    );
  }
};

export default ManagementFeeStudents;
