import React from "react";

const formatMoney = (amount) => {
    return new Intl.NumberFormat("vi-VN", {
    style: "currency",
    currency: "VND",
  }).format(amount);
};

export default formatMoney;
