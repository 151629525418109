import { MainComponentAdmin } from "../../components";
import ManagementFaculty from "../../components/ManagementFaculty/ManagementFaculty";

const FacultyPage = () => {
  return (
    <MainComponentAdmin>
      <ManagementFaculty />
    </MainComponentAdmin>
  );
};

export default FacultyPage;
