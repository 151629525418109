import { DataGrid } from "@mui/x-data-grid";
import {
  useDeleteCourseByIdMutation,
  useFetchCourseQuery,
  useImportFileExcelMutation,
} from "../../../redux/apis/course/course.api";
import { ModalWarning, Skeleton } from "../../../shared/components";
import { courses_columns } from "./columns/course_column";
import MainToolbarAdmin from "../MainComponentAdmin/MainToolbarAdmin";
import { ButtonFields, FileUpload } from "../../../shared/FormElement";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import ModalCreateFormStudent from "../ManagementStudent/ModalCreateFormStudent";
import { useState } from "react";
import ModalUpdateFormStudent from "./ModalUpdateFormCourse";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { resetFile } from "../../../redux/slices/importExcel/importExcelSlice";

const ManagementCourse = () => {
  const actionColumn = [
    {
      headerName: "Actions",
      width: 90,
      renderCell: (params) => {
        return (
          <>
            <ButtonFields
              onClick={() => handleToggleModalUpdate(params.row.id)}
              className="main-action__admin-icon"
            >
              <BorderColorIcon />
            </ButtonFields>

            <ButtonFields
              onClick={() => handleToggleModalDelete(params.id)}
              className="main-action__admin-icon"
            >
              <DeleteIcon />
            </ButtonFields>
          </>
        );
      },
    },
  ];

  const fetchCourses = useFetchCourseQuery();
  const dispatch = useDispatch();
  const importFileState = useSelector((state) => state.importFileExcel);

  const [courseId, setCourseId] = useState(null);
  const [isShowModalCreate, setIsShowModalCrete] = useState(false);
  const [isShowModalUpdate, setIsShowModalUpdate] = useState(false);
  const [isShowModalDelete, setIsShowModalDelete] = useState(false);

  const [doDeleteCourse, deleteCourseResults] = useDeleteCourseByIdMutation();
  const [importFile, importFileResults] = useImportFileExcelMutation();

  const handleToggleModalCreate = () => {
    setIsShowModalCrete(!isShowModalCreate);
  };

  const handleToggleModalUpdate = (courseId) => {
    setIsShowModalUpdate(!isShowModalUpdate);
    setCourseId(courseId);
  };

  const handleToggleModalDelete = (courseId) => {
    setIsShowModalDelete(!isShowModalDelete);
    setCourseId(courseId);
  };

  const handleDeleteCourse = (courseId) => {
    doDeleteCourse(courseId)
      .unwrap()
      .then(() => {
        toast.success("Deleted course successfully!", {
          autoClose: 2000,
        });
        setIsShowModalDelete(!isShowModalDelete);
        setCourseId(null);
      })
      .catch((error) => toast.error(error.data.message));
  };

  const handleImportFile = () => {
    const formData = new FormData();
    formData.set("file", importFileState.fileSelected);

    importFile(formData)
      .unwrap()
      .then(() => {
        toast.success(
          `Import file ${importFileState.fileSelected.name} successfully!`
        );
      })
      .catch((error) => {
        toast.error(error.data.message);
      })
      .finally(() => dispatch(resetFile()));
  };

  if (fetchCourses.isFetching) {
    return <Skeleton times={6} height={"7rem"} />;
  } else {
    const transformedData = fetchCourses.data.map((item, index) => ({
      ...item,
      no: index + 1,
    }));
    return (
      <>
        <MainToolbarAdmin>
          <div className="d-flex align-items-center">
            <FileUpload
              onSubmit={handleImportFile}
              isLoading={importFileResults.isLoading}
            />
            <ButtonFields
              onClick={handleToggleModalCreate}
              subPrimary
              className="ml-5"
            >
              <FontAwesomeIcon
                style={{ fontSize: "1.8rem" }}
                className="mr-3"
                icon={faPlusCircle}
              />
              Create New
            </ButtonFields>
          </div>

          <div style={{ width: "45rem" }}>
            <input
              className="form-input__input"
              placeholder="Enter the course's name..."
            />
          </div>
        </MainToolbarAdmin>

        <div className="data-table" style={{ height: "90vh", width: "100%" }}>
          <DataGrid
            columns={courses_columns.concat(actionColumn)}
            rows={transformedData}
          />
        </div>

        <ModalCreateFormStudent
          show={isShowModalCreate}
          toggleModal={handleToggleModalCreate}
        />

        <ModalUpdateFormStudent
          show={isShowModalUpdate}
          courseId={courseId}
          toggleModal={() => handleToggleModalUpdate(null)}
        />

        {/* MODAL DELETE */}
        <ModalWarning
          show={isShowModalDelete}
          onCancel={() => handleToggleModalDelete()}
          headerWarning="Delete Course"
          footer={
            <div className="d-flex align-items-center justify-content-between">
              <ButtonFields
                type="button"
                onClick={() => handleToggleModalDelete()}
                borderOnly
                className="seller-form__btn"
              >
                Close
              </ButtonFields>
              <ButtonFields
                onClick={() => handleDeleteCourse(courseId)}
                type="button"
                isLoading={deleteCourseResults.isLoading}
                subPrimary
                className="seller-form__btn"
              >
                Confirm Delete
              </ButtonFields>
            </div>
          }
        >
          Are you sure you want to delete this course?
        </ModalWarning>
        {/* MODAL DELETE */}
      </>
    );
  }
};

export default ManagementCourse;
