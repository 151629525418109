import { Sidebar } from "../../components";

import "./AdminHome.scss";

const AdminHome = () => {
  return (
    <div className="row">
      <div className="col-2 pr-0">
        <Sidebar />
      </div>
      <div className="col-10 pl-0">
        <div className="admin-home__widgets"></div>

        <div className="admin-home__charts"></div>
      </div>
    </div>
  );
};

export default AdminHome;
