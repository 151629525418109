import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";

import classes from "./MainComponentAdmin.module.scss";

const MainComponentAdmin = ({ children }) => {
  return (
    <div className={`${classes.MainComponentAdmin} row`}>
      <div className="col-2 pr-0">
        <Sidebar />
      </div>
      <div className={`${classes.MainComponentAdmin__RightSide} col-10 pl-0`}>
        <Navbar />
        {children}
      </div>
    </div>
  );
};

export default MainComponentAdmin;
