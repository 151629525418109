import classes from "./ManagementScholarship.module.scss";

import MainToolbarAdmin from "../MainComponentAdmin/MainToolbarAdmin";
import { Skeleton } from "../../../shared/components";
import { ButtonFields, SelectFields } from "../../../shared/FormElement";
import { FormProvider, useForm } from "react-hook-form";
import React, { memo, useCallback, useEffect, useState } from "react";
import { fetchCourses } from "../../../redux/thunks/admin/cource/courceThunk";
import { useDispatch, useSelector } from "react-redux";
import useThunk from "../../../shared/hooks/useThunk";
import { fetchFaculties } from "../../../redux/thunks/admin/faculty/facultyThunk";
import { toast } from "react-toastify";
import {
  fetchIndustryGroupByCourseIdAndFacultyId,
  fetchStudentsByIndustryGroupIdAndStatus,
  saveScholarshipStudent,
} from "../../../redux/thunks/admin/calculateScholarship/calculateScholarshipThunk";
import { Checkbox } from "@mui/material";
import { fetchBudgetFromIndustryGroupId } from "../../../redux/thunks/admin/budget/budgetThunk";
import formatMoney from "../../../utils/format-money";
import {
  checkedBudgetRemain,
  uncheckedBudgetRemain,
  updateBudgetRemain,
  updateBudgetRemainWithStatusCheck,
  updateStatusDisable,
} from "../../../redux/slices/budget/budgetSlice";
import usePaginate from "../../../shared/hooks/usePaginate";
import Pagination from "../../../shared/components/Pagination/Pagination";

const ManagementScholarship = () => {
  const methods = useForm();
  const dispatch = useDispatch();

  const [checkboxSelected, setCheckboxSelected] = useState([]);
  const [prevPosSelected, setPrevSelected] = useState(0);
  const courseSelected = methods.watch("courseId");
  const facultySelected = methods.watch("facultyId");
  const industryIdSelected = methods.watch("industryId");
  const scholarshipState = useSelector((state) => state.scholarshipSlice);
  const budgetState = useSelector((state) => state.budgetSlice);

  const [currentPage, setCurrentPage] = useState(1);
  const [capacityPage, setCapacityPage] = useState(10);
  const { paginate, updateData } = usePaginate();
  const storage = paginate(currentPage, capacityPage);

  const [doFetchCourses] = useThunk(fetchCourses);
  const [doFetchFaculties] = useThunk(fetchFaculties);
  const [doFetchIndustryGroup] = useThunk(
    fetchIndustryGroupByCourseIdAndFacultyId
  );
  const [doFetchStudentScholarship, isLoadingFetchStudentScholarship] =
    useThunk(fetchStudentsByIndustryGroupIdAndStatus);
  const [doFetchBudgetFromIndustryGroupId] = useThunk(
    fetchBudgetFromIndustryGroupId
  );
  const [doSaveScholarshipStudent, doSaveScholarshipStudentLoading] = useThunk(
    saveScholarshipStudent
  );

  useEffect(() => {
    doFetchCourses();
    doFetchFaculties();

    if (facultySelected && courseSelected) {
      doFetchIndustryGroup({
        courseId: courseSelected,
        facultyId: facultySelected,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseSelected, facultySelected]);

  useEffect(() => {
    if (budgetState.industryGroupData) {
      dispatch(
        updateBudgetRemain(budgetState.industryGroupData.tuitionFeeExtracted)
      );
      dispatch(updateStatusDisable(false));
    }
  }, [budgetState.industryGroupData, dispatch]);

  useEffect(() => {
    if (budgetState.studentScholarshipData) {
      updateData(budgetState.studentScholarshipData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [budgetState.studentScholarshipData]);

  const handleRedirectPage = useCallback((pageNumber) => {
    setCurrentPage(pageNumber);
  }, []);

  const onSubmit = (data) => {
    if (!facultySelected) {
      toast.error("You must choose faculty");
      return;
    }

    if (!courseSelected) {
      toast.error("You must choose course");
      return;
    }

    if (!data.industryId) {
      toast.error("You must choose industry");
      return;
    }

    doFetchStudentScholarship(data.industryId);
    doFetchBudgetFromIndustryGroupId(data.industryId);
  };

  useEffect(() => {
    if (scholarshipState.studentScholarshipData) {
      dispatch(
        updateBudgetRemainWithStatusCheck(
          scholarshipState.studentScholarshipData
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scholarshipState.studentScholarshipData]);

  const handleCalculateBudgetRemain = (
    event,
    receive,
    idStudent,
    currentStudent,
    nextStudent,
    previousStudent
  ) => {
    if (event.target.checked && budgetState.industryGroupData) {
      dispatch(
        checkedBudgetRemain({
          receiveValue: receive,
          idStudentChecked: idStudent,
          currentStudent,
          nextStudent,
        })
      );
    }

    if (!event.target.checked && budgetState.industryGroupData) {
      dispatch(
        uncheckedBudgetRemain({
          receiveValue: receive,
          idStudentChecked: idStudent,
          currentStudent,
          previousStudent,
        })
      );
    }
  };

  const handleSubmitStudentScholarship = () => {
    if (budgetState.studentSelected.length === 0) {
      toast.error("You have to choose student before submit");
      return;
    }
    const studentIds = budgetState.studentSelected.map((student) => student.id);

    const data = {
      studentIds,
      industryGroupId: industryIdSelected,
      remainingBudget: budgetState.budgetRemain,
    };

    doSaveScholarshipStudent(data);
    setCheckboxSelected([]);
    toast.success("You saved data successfully!", { autoClose: 2000 });
  };

  if (isLoadingFetchStudentScholarship) {
    return <Skeleton times={6} height={"7rem"} />;
  } else {
    return (
      <>
        <MainToolbarAdmin>
          <FormProvider {...methods}>
            <form
              style={{ width: "100%" }}
              onSubmit={methods.handleSubmit(onSubmit)}
            >
              <div className="row w-100">
                <div className="col-3">
                  <SelectFields
                    fieldName="courseId"
                    htmlFor="course"
                    label="Course (*)"
                    initialValue=""
                  >
                    <option value="">Choose your course</option>

                    {scholarshipState.courseData &&
                      scholarshipState.courseData.map((course) => (
                        <option key={course.id} value={course.id}>
                          {course.name}
                        </option>
                      ))}
                  </SelectFields>
                </div>

                {scholarshipState.facultiesData && (
                  <div className="col-3">
                    <SelectFields
                      fieldName="facultyId"
                      htmlFor="faculty"
                      label="Faculty (*)"
                      initialValue=""
                    >
                      <option value="">Choose your faculty</option>

                      {scholarshipState.facultiesData &&
                        scholarshipState.facultiesData.map((faculty) => (
                          <option key={faculty.id} value={faculty.id}>
                            {faculty.name}
                          </option>
                        ))}
                    </SelectFields>
                  </div>
                )}

                {scholarshipState.scholarshipData && (
                  <div className="col-3">
                    <SelectFields
                      fieldName="industryId"
                      htmlFor="industry"
                      label="Industry (*)"
                      initialValue=""
                    >
                      <option value="">Choose your industry</option>

                      {scholarshipState.scholarshipData &&
                        scholarshipState.scholarshipData.map((industry) => (
                          <option key={industry.id} value={industry.id}>
                            {industry.name}
                          </option>
                        ))}
                    </SelectFields>
                  </div>
                )}
              </div>

              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <ButtonFields
                    style={{ height: "3.5rem" }}
                    type="submit"
                    primary
                    className="m-3"
                  >
                    VIEW DATA
                  </ButtonFields>

                  <ButtonFields
                    style={{ height: "3.5rem" }}
                    type="button"
                    borderOnly
                    className="m-3"
                    onClick={handleSubmitStudentScholarship}
                    isLoading={doSaveScholarshipStudentLoading}
                  >
                    Save Data
                  </ButtonFields>
                </div>

                {budgetState.industryGroupData && (
                  <div
                    className={`${classes.ScholarShipBudget} d-flex flex-column`}
                  >
                    <p className={classes.ScholarShipBudget__text}>
                      <span>Tuition Fee Extracted:</span>
                      {formatMoney(
                        budgetState.industryGroupData.tuitionFeeExtracted
                      )}
                    </p>

                    <p className={`${classes.ScholarShipBudget__text} ml-3`}>
                      <span>Budget Remain:</span>
                      {formatMoney(budgetState.budgetRemain)}
                    </p>
                  </div>
                )}
              </div>
            </form>
          </FormProvider>
        </MainToolbarAdmin>

        {scholarshipState.studentScholarshipData && (
          <div className="data-table">
            <div className={`table-responsive table__customize`}>
              <table className={`table`}>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Class Name</th>
                    <th>Student Name</th>
                    <th>Point HB</th>
                    <th>Point RL</th>
                    <th>Registered Credits</th>
                    <th>Accumulated Credits</th>
                    <th>Has SubjectDebt</th>
                    <th>Has Overtime</th>
                    <th>Total Paid</th>
                    <th>Level</th>
                    <th>Receive</th>
                    <th>Status</th>
                    <th>Approve</th>
                  </tr>
                </thead>

                <tbody>
                  {storage.length > 0 &&
                    storage.map((data, index) => {
                      return (
                        <tr key={index}>
                          <td>{data.id}</td>
                          <td>{data.nameClass}</td>
                          <td>{data.name}</td>
                          <td>{data.pointHb}</td>
                          <td>{data.pointRl}</td>
                          <td>{data.registeredCredits}</td>
                          <td>{data.accumulatedCredits}</td>
                          <td>{data.subjectDebt ? "true" : "false"}</td>
                          <td>{data.overtimeEnrollment ? "true" : "false"}</td>
                          <td>{formatMoney(data.totalPaid)}</td>
                          <td>{data.level}</td>
                          <td>{formatMoney(data.receive)}</td>
                          <td>{data.status}</td>

                          <td style={{ lineHeight: "50px" }}>
                            <Checkbox
                              disabled={
                                budgetState.isDisabledApprove &&
                                !checkboxSelected.includes(data.id)
                              }
                              checked={data.checked}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                              onChange={(event) => {
                                const isChecked = event.target.checked;
                                const checkboxId = data.id;
                                let updatedSelectedCheckboxIds = [];

                                if (prevPosSelected < index) {
                                  toast.error("You have to select by order");
                                  return;
                                }
                                setPrevSelected(index + 1);

                                if (isChecked) {
                                  updatedSelectedCheckboxIds = [
                                    ...checkboxSelected,
                                    checkboxId,
                                  ];
                                }

                                if (!isChecked) {
                                  updatedSelectedCheckboxIds =
                                    checkboxSelected.filter(
                                      (id) => id !== checkboxId
                                    );
                                }
                                setCheckboxSelected(updatedSelectedCheckboxIds);

                                handleCalculateBudgetRemain(
                                  event,
                                  data.receive,
                                  data.id,
                                  data,
                                  index === storage.length - 1
                                    ? null
                                    : storage[index + 1],
                                  index > 0 ? storage[index - 1] : null
                                );
                              }}
                            />
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <div style={{ padding: "11rem 0 3rem 0" }}>
          <Pagination
            capacityPage={capacityPage}
            totalData={scholarshipState.studentScholarshipData.length}
            currentPage={currentPage}
            onRedirect={handleRedirectPage}
          />
        </div>
      </>
    );
  }
};

export default memo(ManagementScholarship);
