import formatMoney from "../../../../utils/format-money";

export const list_scholarship_columns = [
  { field: "no", headerName: "No.", width: 50 },
  { field: "id", headerName: "ID", width: 150 },
  { field: "nameClass", headerName: "Class Name", width: 150 },
  { field: "name", headerName: "Student Name", width: 200 },
  { field: "pointHb", headerName: "Point HB", width: 150 },
  { field: "pointRl", headerName: "Point RL", width: 150 },
  { field: "registeredCredits", headerName: "Registered Credits", width: 150 },
  { field: "accumulatedCredits", headerName: "Accumulated Credits", width: 200 },
  { field: "subjectDebt", headerName: "Has SubjectDebt", width: 150 },
  { field: "overtimeEnrollment", headerName: "Has Overtime", width: 150 },
  {
    field: "totalPaid",
    headerName: "Total Paid",
    width: 150,
    renderCell: (params) => {
      return <div>{formatMoney(params.row.totalPaid)}</div>;
    },
  },
  { field: "level", headerName: "Level", width: 20 },
  {
    field: "receive",
    headerName: "Receive",
    width: 150,
    renderCell: (params) => {
      return <div>{formatMoney(params.row.receive)}</div>;
    },
  },
  { field: "status", headerName: "Status", width: 150 },
];
