import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShowModalCreate: false,
  isShowModalUpdate: false,
  idClass: null,
  isShowModalDelete: false,
};

const classesSlices = createSlice({
  name: "classesSlice",
  initialState,
  reducers: {
    toggleShowModalCreate: (state) => {
      state.isShowModalCreate = !state.isShowModalCreate;
    },
    toggleShowModalUpdate: (state, action) => {
      state.isShowModalUpdate = !state.isShowModalUpdate;

      if (state.isShowModalUpdate) {
        state.idClass = action.payload;
      }
    },
    toggleShowModalDelete: (state, action) => {
      state.isShowModalDelete = !state.isShowModalDelete;

      if (state.isShowModalDelete) {
        state.idClass = action.payload;
      }
    },
  },
});

export const {
  toggleShowModalCreate,
  toggleShowModalUpdate,
  toggleShowModalDelete,
} = classesSlices.actions;
export const classesReducer = classesSlices.reducer;
