import classes from "./ManagementClasses.module.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid } from "@mui/x-data-grid";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import MainToolbarAdmin from "../MainComponentAdmin/MainToolbarAdmin";
import { ModalWarning, Skeleton } from "../../../shared/components";
import {
  useDeleteClassMutation,
  useFetchClassesQuery,
  useImportFileExcelMutation,
} from "../../../redux/apis/classes/classes.api";
import { ButtonFields, FileUpload } from "../../../shared/FormElement";
import { classes_columns } from "./columns/classes_columns";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleShowModalCreate,
  toggleShowModalDelete,
  toggleShowModalUpdate,
} from "../../../redux/slices/classes/classesSlice";
import ModalCreateFormClasses from "./ModalCreateFormClasses";
import ModalUpdateFormClasses from "./ModalUpdateFormClasses";
import { toast } from "react-toastify";
import { resetFile } from "../../../redux/slices/importExcel/importExcelSlice";

const ManagementClasses = () => {
  const actionColumn = [
    {
      headerName: "Actions",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <ButtonFields
              onClick={() => handleShowUpdateForm(params.id)}
              className="main-action__admin-icon"
            >
              <BorderColorIcon />
            </ButtonFields>

            <ButtonFields
              onClick={() => dispatch(toggleShowModalDelete(params.id))}
              className="main-action__admin-icon"
            >
              <DeleteIcon />
            </ButtonFields>
          </>
        );
      },
    },
  ];

  const dispatch = useDispatch();
  const classesState = useSelector((state) => state.classesSlice);
  const listClasses = useFetchClassesQuery();
  const importFileState = useSelector((state) => state.importFileExcel);
  const [importFile, importFileResults] = useImportFileExcelMutation();

  const [doDeleteClass, doDeleteClassResults] = useDeleteClassMutation();

  const handleShowUpdateForm = (classId) => {
    dispatch(toggleShowModalUpdate(classId));
  };

  const handleDeleteLocation = () => {
    doDeleteClass(classesState.idClass)
      .unwrap()
      .then(() => {
        toast.success("Deleted class successfully!");
        dispatch(toggleShowModalUpdate());
      })
      .catch((error) =>
        toast.error(error.data.message || "This class have students")
      );
  };

  const handleImportFile = () => {
    const formData = new FormData();
    formData.set("file", importFileState.fileSelected);

    importFile(formData)
      .unwrap()
      .then(() => {
        toast.success(
          `Import file ${importFileState.fileSelected.name} successfully!`
        );
      })
      .catch((error) => {
        toast.error(error.data.message);
      })
      .finally(() => dispatch(resetFile()));
  };

  if (listClasses.isFetching) {
    return <Skeleton times={6} height={"7rem"} />;
  } else if (listClasses.isError) {
    return <div>Error</div>;
  } else {

    const transformedData = listClasses.data.map((item, index) => ({
      ...item,
      no: index + 1,
    }));

    return (
      <>
        <MainToolbarAdmin>
          <div className="d-flex align-items-center">
            <FileUpload
              isLoading={importFileResults.isLoading}
              onSubmit={handleImportFile}
            />

            <ButtonFields
              onClick={() => dispatch(toggleShowModalCreate())}
              subPrimary
              className="ml-5"
            >
              <FontAwesomeIcon
                style={{ fontSize: "1.8rem" }}
                className="mr-3"
                icon={faPlusCircle}
              />
              Create New
            </ButtonFields>
          </div>

          <div style={{ width: "45rem" }}>
            <input
              className="form-input__input"
              placeholder="Enter the class's name..."
            />
          </div>
        </MainToolbarAdmin>

        <div className="data-table" style={{ height: "90vh", width: "100%" }}>
          <DataGrid
            rows={transformedData}
            columns={classes_columns.concat(actionColumn)}
            checkboxSelection={false}
            localeText={{
              toolbarDensity: "Size",
              toolbarDensityLabel: "Size",
              toolbarDensityCompact: "Small",
              toolbarDensityStandard: "Medium",
              toolbarDensityComfortable: "Large",
            }}
          />
        </div>

        <ModalCreateFormClasses />
        {classesState.idClass && <ModalUpdateFormClasses />}

        {/* MODAL DELETE */}
        <ModalWarning
          show={classesState.isShowModalDelete}
          onCancel={() => dispatch(toggleShowModalDelete())}
          headerWarning="Delete Your  Class"
          footer={
            <div className="d-flex align-items-center justify-content-between">
              <ButtonFields
                type="button"
                onClick={() => dispatch(toggleShowModalDelete())}
                borderOnly
                className="seller-form__btn"
              >
                Close
              </ButtonFields>
              <ButtonFields
                onClick={handleDeleteLocation}
                type="button"
                isLoading={doDeleteClassResults.isLoading}
                subPrimary
                className="seller-form__btn"
              >
                Confirm Delete
              </ButtonFields>
            </div>
          }
        >
          Are you sure you want to delete this class?
        </ModalWarning>
        {/* MODAL DELETE */}
      </>
    );
  }
};

export default ManagementClasses;
