import classes from "./MainComponentAdmin.module.scss";

const MainToolbarAdmin = ({ className, children }) => {
  return (
    <div className={`${classes.MainToolbar} ${className}`}>
      <div className={classes.MainToolbar__group}>{children}</div>
    </div>
  );
};

export default MainToolbarAdmin;
