import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { API_BE_SIDE } from "../../link_api";
import pause from "../../../utils/pause";

const useSemesterApis = createApi({
  reducerPath: "semesterApi",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BE_SIDE + "semesters",
    fetchFn: async (...args) => {
      await pause(600);
      return fetch(...args);
    },
    prepareHeaders: (headers) => {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const token = userData?.token;

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      fetchSemesters: builder.query({
        providesTags: (result, error, args) => {
          return [{ type: "Semesters" }];
        },
        query: () => {
          return {
            method: "GET",
          };
        },
      }),
      fetchSemesterById: builder.query({
        invalidatesTags: (result, error, args) => {
          return [{ type: "Semesters" }];
        },
        query: (semesterId) => {
          return {
            url: `${semesterId}`,
            method: "GET",
          };
        },
      }),
      createSemester: builder.mutation({
        invalidatesTags: (result, error, args) => {
          return [{ type: "Semesters" }];
        },
        query: (data) => {
          return {
            body: data,
            method: "POST",
          };
        },
      }),
      updateSemester: builder.mutation({
        invalidatesTags: (result, error, args) => {
          return [{ type: "Semesters" }];
        },
        query: (payload) => {
          return {
            url: `${payload.id}`,
            body: payload.data,
            method: "PUT",
          };
        },
      }),
      deleteSemester: builder.mutation({
        invalidatesTags: (result, error, args) => {
          return [{ type: "Semesters" }];
        },
        query: (semesterId) => {
          return {
            url: `${semesterId}`,
            method: "DELETE",
          };
        },
      }),
    };
  },
});

export const {
  useCreateSemesterMutation,
  useFetchSemesterByIdQuery,
  useDeleteSemesterMutation,
  useFetchSemestersQuery,
  useUpdateSemesterMutation,
} = useSemesterApis;
export { useSemesterApis };
