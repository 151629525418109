export const student_columns = [
  { field: "no", headerName: "No.", width: 50 },
  { field: "id", headerName: "ID", width: 130 },
  {
    field: "classId",
    headerName: "Class ID",
    width: 150,
  },
  { field: "name", headerName: "Name", width: 220 },
  {
    field: "pointHb",
    headerName: "Point HB",
    width: 100,
  },
  {
    field: "pointRl",
    headerName: "Point RL",
    width: 100,
  },
  {
    field: "registeredCredits",
    headerName: "Registered Credits",
    width: 150,
  },
  {
    field: "accumulatedCredits",
    headerName: "Accumulated Credits",
    width: 200,
  },
];
