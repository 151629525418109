import "./StudentPage.scss";
import { ManagementStudent, MainComponentAdmin } from "../../components";

const StudentPage = () => {
  return (
    <MainComponentAdmin>
      <ManagementStudent />
    </MainComponentAdmin>
  );
};

export default StudentPage;
