import { MainComponentAdmin } from "../../components";
import ManagementSubjectDebt from "../../components/ManagementSubjectDebt/ManagementSubjectDebt";

const SubjectDebtPage = () => {
  return (
    <MainComponentAdmin>
      <ManagementSubjectDebt />
    </MainComponentAdmin>
  );
};

export default SubjectDebtPage;
