import { FormProvider, useForm } from "react-hook-form";
import {
  ButtonFields,
  InputFields,
  SelectFields,
} from "../../../shared/FormElement";
import { Modal } from "../../../shared/components";
import { toast } from "react-toastify";
import { useFetchCourseQuery } from "../../../redux/apis/course/course.api";
import { useDispatch, useSelector } from "react-redux";
import { toggleShowModalUpdate } from "../../../redux/slices/industryGroup/industryGroupSlices";
import { useEffect } from "react";
import {
  useFetchIndustryGroupByIdQuery,
  useUpdateIndustryGroupMutation,
} from "../../../redux/apis/industry/industry.api";
import { fetchFaculties } from "../../../redux/thunks/admin/faculty/facultyThunk";
import useThunk from "../../../shared/hooks/useThunk";

const ModalUpdateFormIndustry = () => {
  const methods = useForm({ mode: "all" });

  const fetchFacultyState = useSelector((state) => state.scholarshipSlice);
  const [doFetchFaculties] = useThunk(fetchFaculties);

  const [updateIndustry, updateIndustryResults] =
    useUpdateIndustryGroupMutation();

  const courseSelected = methods.watch("courseId");
  const facultySelected = methods.watch("facultyId");
  const fetchCourses = useFetchCourseQuery();
  const dispatch = useDispatch();
  const industryGroupState = useSelector((state) => state.industryGroupSlice);
  const fetchIndustryGroupById = useFetchIndustryGroupByIdQuery(
    industryGroupState.industryGroupId
  );

  useEffect(() => {
    methods.setValue("id", `${courseSelected}${facultySelected}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [facultySelected, courseSelected]);

  useEffect(() => {
    doFetchFaculties();
  }, [doFetchFaculties]);

  useEffect(() => {
    if (industryGroupState.industryGroupId) {
      methods.reset(fetchIndustryGroupById.data);
    }
  }, [
    fetchIndustryGroupById.data,
    industryGroupState.industryGroupId,
    methods,
  ]);

  const onSubmit = (data) => {
    updateIndustry({ id: industryGroupState.industryGroupId, data: data })
      .unwrap()
      .then(() => {
        toast.success("Updated industry group successfully!", {
          autoClose: 2000,
        });
        dispatch(toggleShowModalUpdate());
      })
      .catch((error) => {
        toast.error(error.data.message);
      });
  };

  return (
    <>
      <FormProvider {...methods}>
        <Modal
          onSubmit={methods.handleSubmit(onSubmit)}
          show={industryGroupState.isShowModalUpdate}
          onCancel={() => dispatch(toggleShowModalUpdate())}
          footer={
            <div className="d-flex flex-row-reverse aligns-item">
              <ButtonFields
                type="submit"
                primary
                className="ml-5"
                isLoading={updateIndustryResults.isLoading}
              >
                SUBMIT
              </ButtonFields>
              <ButtonFields
                type="button"
                onClick={() => dispatch(toggleShowModalUpdate())}
                borderOnly
              >
                BACK
              </ButtonFields>
            </div>
          }
        >
          <InputFields
            fieldName="id"
            type="text"
            initialValue={methods.getValues("id")}
            placeholder="Enter Industry Name"
            label="Industry ID (*)"
            htmlFor="id"
            disabled={
              !methods.getValues("courseId") || !methods.getValues("facultyId")
            }
          />

          <InputFields
            fieldName="name"
            type="text"
            placeholder="Enter Industry Name"
            label="Industry Name (*)"
            htmlFor="name"
          />

          <SelectFields
            fieldName="facultyId"
            htmlFor="faculty"
            label="Faculty (*)"
            initialValue=""
          >
            <option value="">Choose your faculty</option>

            {fetchFacultyState.facultiesData &&
              fetchFacultyState.facultiesData.map((industry) => (
                <option key={industry.id} value={industry.id}>
                  {industry.name}
                </option>
              ))}
          </SelectFields>

          <SelectFields
            fieldName="courseId"
            htmlFor="course"
            label="Faculty (*)"
            initialValue=""
          >
            <option value="">Choose your course</option>

            {fetchCourses.data &&
              fetchCourses.data.map((course) => (
                <option key={course.id} value={course.id}>
                  {course.name}
                </option>
              ))}
          </SelectFields>

          <InputFields
            fieldName="previousBudgetRemain"
            type="number"
            placeholder="Enter Budget Remain"
            label="Budget Remain (*)"
            htmlFor="name"
          />
        </Modal>
      </FormProvider>
    </>
  );
};

export default ModalUpdateFormIndustry;
