import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isShowModalUpdate: false,
  isShowModalCreate: false,
  industryGroupId: null,
};

const industryGroupSlice = createSlice({
  name: "industryGroupSlice",
  initialState,
  reducers: {
    toggleShowModalCreate: (state, action) => {
      state.isShowModalCreate = !state.isShowModalCreate;
    },
    toggleShowModalUpdate: (state, action) => {
      state.isShowModalUpdate = !state.isShowModalUpdate;

      if (state.isShowModalUpdate) {
        state.industryGroupId = action.payload;
      }
    },
  },
});

export const { toggleShowModalUpdate, toggleShowModalCreate } =
  industryGroupSlice.actions;
export const industryGroupReducer = industryGroupSlice.reducer;
