import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { API_BE_SIDE } from "../../link_api";
import pause from "../../../utils/pause";

const useCourseApis = createApi({
  reducerPath: "courses",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BE_SIDE + "courses",
    fetchFn: async (...args) => {
      await pause(600);
      return fetch(...args);
    },
    prepareHeaders: (headers) => {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const token = userData?.token;

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      fetchCourse: builder.query({
        providesTags: (result, error, args) => {
          return [{ type: "Courses" }];
        },
        query: () => {
          return {
            method: "GET",
          };
        },
      }),
      fetchCourseById: builder.query({
        providesTags: (result, error, args) => {
          return [{ type: "Courses" }];
        },
        query: (id) => {
          return {
            url: `${id}`,
            method: "GET",
          };
        },
      }),
      addCourse: builder.mutation({
        invalidatesTags: (result, error, args) => {
          return [{ type: "Courses" }];
        },
        query: (id) => {
          return {
            url: `${id}`,
            method: "GET",
          };
        },
      }),
      updateCourse: builder.mutation({
        invalidatesTags: (result, error, args) => {
          return [{ type: "Courses" }];
        },
        query: (payload) => {
          return {
            url: `${payload.id}`,
            body: payload.data,
            method: "PUT",
          };
        },
      }),
      deleteCourseById: builder.mutation({
        invalidatesTags: (result, error, args) => {
          return [{ type: "Courses" }];
        },
        query: (id) => {
          return {
            url: `${id}`,
            method: "DELETE",
          };
        },
      }),
      importFileExcel: builder.mutation({
        invalidatesTags: (result, error, args) => {
          return [{ type: "Courses" }];
        },
        query: (file) => {
          return {
            url: "/import",
            method: "POST",
            body: file,
          };
        },
      }),
    };
  },
});

export const {
  useFetchCourseQuery,
  useAddCourseMutation,
  useUpdateCourseMutation,
  useFetchCourseByIdQuery,
  useDeleteCourseByIdMutation,
  useImportFileExcelMutation,
} = useCourseApis;
export { useCourseApis };
