import { useRef } from "react";

import DriveFolderUploadIcon from "@mui/icons-material/DriveFolderUpload";
import ButtonFields from "../ButtonFields/ButtonFields";

import classes from "./FileUpload.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { onPickerFile } from "../../../redux/slices/importExcel/importExcelSlice";

const FileUpload = ({ onSubmit, isLoading }) => {
  const inputRef = useRef();
  const dispatch = useDispatch();
  const importFileState = useSelector((state) => state.importFileExcel);

  const handlePickedFile = (event) => {
    if (event.target.files && event.target.files.length === 1) {
      dispatch(onPickerFile(event.target.files[0]));
    }
  };

  const handlePickFile = () => {
    inputRef.current.click();
  };

  return (
    <div className={classes.FileUpload}>
      <ButtonFields primary onClick={handlePickFile}>
        <DriveFolderUploadIcon className={classes.FileUpload__icon} />
        <span>Upload File Excel</span>
      </ButtonFields>

      <input
        type="file"
        accept=".xlsx"
        style={{ display: "none" }}
        ref={inputRef}
        onChange={handlePickedFile}
      />

      {importFileState.fileSelected && (
        <>
          <p className={classes.FileUpload__name}>
            <span style={{ fontWeight: 600 }}>File selected: </span>
            {importFileState.fileSelected.name}
          </p>

          <ButtonFields
            type="button"
            onClick={onSubmit}
            borderOnly
            className={classes.FileUpload__btn}
            isLoading={isLoading}
          >
            Import File
          </ButtonFields>
        </>
      )}
    </div>
  );
};

export default FileUpload;
