import classes from "./ManagementStudent.module.scss";
import "../DataTable/DataTable.scss";

import { useDispatch, useSelector } from "react-redux";

import { DataGrid } from "@mui/x-data-grid";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import DeleteIcon from "@mui/icons-material/Delete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";

import ModalCreateFormStudent from "./ModalCreateFormStudent.js";
import {
  Skeleton,
  CustomToolbar,
  ModalWarning,
} from "../../../shared/components";
import { ButtonFields, FileUpload } from "../../../shared/FormElement";
import { student_columns } from "./columns/student_columns";
import {
  useDeleteStudentMutation,
  useFetchStudentsQuery,
  useImportFileMutation,
} from "../../../redux/apis/student/student.api";
import ModalUpdateFormStudent from "./ModalUpdateFormStudent";
import {
  toggleShowModalCreate,
  toggleShowModalDelete,
  toggleShowModalUpdate,
} from "../../../redux/slices/student/addStudent/addStudentSlices";
import MainToolbarAdmin from "../MainComponentAdmin/MainToolbarAdmin";
import { toast } from "react-toastify";
import { resetFile } from "../../../redux/slices/importExcel/importExcelSlice";

const ManagementStudents = () => {
  const actionColumn = [
    {
      headerName: "Actions",
      width: 90,
      renderCell: (params) => {
        return (
          <>
            <ButtonFields
              onClick={() => handleShowUpdateForm(params.id)}
              className={classes.ManagementStudent__icon}
            >
              <BorderColorIcon />
            </ButtonFields>

            <ButtonFields
              onClick={() => dispatch(toggleShowModalDelete(params.id))}
              className={classes.ManagementStudent__icon}
            >
              <DeleteIcon />
            </ButtonFields>
          </>
        );
      },
    },
  ];

  const listStudents = useFetchStudentsQuery();
  const [deleteStudent, deleteStudentResults] = useDeleteStudentMutation();
  const [importFile, importFileResults] = useImportFileMutation();
  const importFileState = useSelector((state) => state.importFileExcel);

  const dispatch = useDispatch();

  const studentState = useSelector((state) => state.addStudent);

  const handleShowUpdateForm = (studentId) => {
    dispatch(toggleShowModalUpdate(studentId));
  };

  const handleToggleModalCreate = () => {
    dispatch(toggleShowModalCreate());
  };

  const handleOnSubmit = () => {
    const formData = new FormData();
    formData.set("file", importFileState.fileSelected);

    importFile(formData)
      .unwrap()
      .then(() => {
        toast.success(
          `Import file ${importFileState.fileSelected.name} successfully!`
        );
      })
      .catch((error) => {
        toast.error(error.data.message);
      })
      .finally(() => dispatch(resetFile()));
  };

  const handleDeleteStudent = () => {
    if (studentState.studentId) {
      deleteStudent(studentState.studentId)
        .unwrap()
        .then(() => {
          toast.success(
            `Deleted student with id ${studentState.studentId} successfully!`
          );

          dispatch(toggleShowModalDelete());
        })
        .catch((error) => toast.error(error.data.message));
    }
  };

  if (listStudents.isFetching) {
    return <Skeleton times={6} height={"7rem"} />;
  } else if (listStudents.isError) {
    return <div>Error</div>;
  } else {

    const transformedData = listStudents.data.map((item, index) => ({
      ...item,
      no: index + 1,
    }));
    return (
      <>
        <MainToolbarAdmin>
          <div className="d-flex align-items-center">
            <FileUpload
              onSubmit={handleOnSubmit}
              isLoading={importFileResults.isLoading}
            />

            <ButtonFields
              onClick={handleToggleModalCreate}
              subPrimary
              className="ml-5"
            >
              <FontAwesomeIcon
                style={{ fontSize: "1.8rem" }}
                className="mr-3"
                icon={faPlusCircle}
              />
              Create New
            </ButtonFields>
          </div>

          <div style={{ width: "45rem" }}>
            <input
              className="form-input__input"
              placeholder="Enter student's name..."
            />
          </div>
        </MainToolbarAdmin>

        <div className="data-table" style={{ height: "90vh", width: "100%" }}>
          <DataGrid
            rows={transformedData}
            columns={student_columns.concat(actionColumn)}
            localeText={{
              toolbarDensity: "Size",
              toolbarDensityLabel: "Size",
              toolbarDensityCompact: "Small",
              toolbarDensityStandard: "Medium",
              toolbarDensityComfortable: "Large",
            }}
            components={{
              Toolbar: CustomToolbar,
            }}
          />
        </div>

        <ModalCreateFormStudent />
        {studentState.studentId && studentState.isShowModalUpdate && (
          <ModalUpdateFormStudent />
        )}

        {/* MODAL DELETE */}
        <ModalWarning
          show={studentState.isShowModalDelete}
          onCancel={() => dispatch(toggleShowModalDelete())}
          headerWarning="Delete Student"
          footer={
            <div className="d-flex align-items-center justify-content-between">
              <ButtonFields
                type="button"
                onClick={() => dispatch(toggleShowModalDelete())}
                borderOnly
                className="seller-form__btn"
              >
                Close
              </ButtonFields>
              <ButtonFields
                onClick={handleDeleteStudent}
                type="button"
                isLoading={deleteStudentResults.isLoading}
                subPrimary
                className="seller-form__btn"
              >
                Confirm Delete
              </ButtonFields>
            </div>
          }
        >
          Are you sure you want to delete this class?
        </ModalWarning>
        {/* MODAL DELETE */}
      </>
    );
  }
};

export default ManagementStudents;
