import { MainComponentAdmin } from "../../components";
import ManagementCourse from "../../components/ManagementCourse/ManagementCourse";

const CoursePage = () => {
  return (
    <MainComponentAdmin>
      <ManagementCourse />
    </MainComponentAdmin>
  );
};

export default CoursePage;
