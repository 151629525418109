import { createAsyncThunk } from "@reduxjs/toolkit";
import createApiClient from "../../../../shared/hooks/useAxios";

const apiClient = createApiClient();

export const fetchBudgetFromIndustryGroupId = createAsyncThunk(
  "budget/fetchBudgetFromIndustryGroupId",
  async (industryGroupId) => {
    const response = await apiClient.get(
      `/budget/by-industry-group?industryGroupId=${industryGroupId}`
    );

    return response.data;
  }
);
