import { MainComponentAdmin } from "../../components";
import ManagementBudget from "../../components/ManagementBudget/ManagementBudget";

const BudgetPage = () => {
  return (
    <MainComponentAdmin>
      <ManagementBudget />
    </MainComponentAdmin>
  );
};

export default BudgetPage;
