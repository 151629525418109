import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { API_BE_SIDE } from "../../link_api";
import pause from "../../../utils/pause";

const useIndustryApis = createApi({
  reducerPath: "industry",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BE_SIDE + "industry-group",
    fetchFn: async (...args) => {
      await pause(600);
      return fetch(...args);
    },
    prepareHeaders: (headers) => {
      const userData = JSON.parse(localStorage.getItem("userData"));
      const token = userData?.token;

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => {
    return {
      fetchIndustries: builder.query({
        providesTags: (result, error, args) => {
          return [{ type: "Industry" }];
        },
        query: () => {
          return {
            method: "GET",
          };
        },
      }),
      fetchIndustryGroupById: builder.query({
        providesTags: () => {
          return [{ type: "Industry" }];
        },
        query: (id) => {
          return {
            url: `${id}`,
            method: "GET",
          };
        },
      }),
      addIndustryGroup: builder.mutation({
        invalidatesTags: () => {
          return [{ type: "Industry" }];
        },
        query: (data) => {
          return {
            body: data,
            method: "POST",
          };
        },
      }),
      updateIndustryGroup: builder.mutation({
        invalidatesTags: () => {
          return [{ type: "Industry" }];
        },
        query: (payload) => {
          return {
            url: `${payload.id}`,
            body: payload.data,
            method: "PUT",
          };
        },
      }),
      deleteIndustryGroup: builder.mutation({
        invalidatesTags: () => {
          return [{ type: "Industry" }];
        },
        query: (id) => {
          return {
            url: `${id}`,
            method: "DELETE",
          };
        },
      }),
      importFileExcel: builder.mutation({
        invalidatesTags: (result, error, args) => {
          return [{ type: "Industry" }];
        },
        query: (file) => {
          return {
            url: "/import",
            method: "POST",
            body: file,
          };
        },
      }),
    };
  },
});

export const {
  useFetchIndustriesQuery,
  useAddIndustryGroupMutation,
  useFetchIndustryGroupByIdQuery,
  useUpdateIndustryGroupMutation,
  useDeleteIndustryGroupMutation,
  useImportFileExcelMutation
} = useIndustryApis;
export { useIndustryApis };
