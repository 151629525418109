import { createAsyncThunk } from "@reduxjs/toolkit";
import createApiClient from "../../../../shared/hooks/useAxios";
import pause from "../../../../utils/pause";

const apiClient = createApiClient();

export const fetchIndustryGroupByCourseIdAndFacultyId = createAsyncThunk(
  "calculateScholarship/fetchIndustryGroupByCourseIdAndFacultyId",
  async ({ courseId, facultyId }) => {
    await pause(600);
    console.log("facultyId", facultyId);

    const response = await apiClient.get(
      `/industry-group/by-course-faculty?courseId=${courseId}&facultyId=${facultyId}`
    );

    return response.data;
  }
);

export const fetchStudentListByClassId = createAsyncThunk(
  "calculateScholarship/fetchStudents",
  async (classId) => {
    const response = await apiClient.get(
      `/calculate/find-students?classId=${classId}`
    );

    return response.data;
  }
);

export const fetchStudentsByIndustryGroupIdAndStatus = createAsyncThunk(
  "calculateScholarship/fetchStudentsByIndustryGroupIdAndStatus",
  async (industryGroupId) => {
    await pause(600);

    const response = await apiClient.get(
      `/calculates/by-industry-group/status?industryGroupId=${industryGroupId}`
    );

    return response.data;
  }
);

export const saveScholarshipStudent = createAsyncThunk(
  "calculateScholarship/saveScholarshipStudent",
  async (data) => {
    await pause(600);

    const response = await apiClient.put(
      "calculates/save-scholarship-student",
      data
    );

    return response.data;
  }
);

export const fetchScholarships = createAsyncThunk(
  "calculateScholarship/fetchScholarships",
  async (facultyId) => {
    await pause(600);

    const response = await apiClient.get(
      `calculates/by-faculty/scholarship?facultyId=${facultyId}`
    );

    return response.data;
  }
);
