import { FormProvider, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "../../../shared/components";
import { toggleShowModalUpdate } from "../../../redux/slices/classes/classesSlice";
import {
  ButtonFields,
  InputFields,
  SelectFields,
} from "../../../shared/FormElement";
import { VALIDATOR_REQUIRED } from "../../../shared/util/validators";
import {
  useFetchClassByIdQuery,
  useUpdateClassMutation,
} from "../../../redux/apis/classes/classes.api";
import { useEffect } from "react";
import { useFetchIndustriesQuery } from "../../../redux/apis/industry/industry.api";
import { toast } from "react-toastify";

const ModalUpdateFormClasses = () => {
  const methods = useForm();

  const dispatch = useDispatch();
  const classesState = useSelector((state) => state.classesSlice);

  const fetchAllIndustries = useFetchIndustriesQuery();
  const fetchClassById = useFetchClassByIdQuery(classesState.idClass);

  const [doUpdateClass, updateClassResults] = useUpdateClassMutation();

  useEffect(() => {
    if (fetchClassById.data) {
      methods.reset(fetchClassById.data);
    }
  }, [classesState.idClass, fetchClassById, methods]);

  const onSubmit = (data) => {
    if (classesState.idClass) {
      doUpdateClass({ id: classesState.idClass, data })
        .unwrap()
        .then(() => {
          toast.success("Update class successfully", { autoClose: 2000 });
        })
        .catch((error) => {
          toast.error(error.data.message);
        });
    }
  };

  return (
    <>
      {fetchClassById.data && (
        <FormProvider {...methods}>
          <Modal
            onSubmit={methods.handleSubmit(onSubmit)}
            show={classesState.isShowModalUpdate}
            onCancel={() => dispatch(toggleShowModalUpdate())}
            footer={
              <div className="d-flex flex-row-reverse aligns-item">
                <ButtonFields
                  type="submit"
                  primary
                  className="ml-5"
                  isLoading={updateClassResults.isLoading}
                >
                  SUBMIT
                </ButtonFields>
                <ButtonFields
                  type="button"
                  onClick={() => dispatch(toggleShowModalUpdate())}
                  borderOnly
                >
                  BACK
                </ButtonFields>
              </div>
            }
          >
            <InputFields
              fieldName="id"
              classNameForm="mr-5"
              type="text"
              placeholder="Enter ID"
              label="ID (*)"
              htmlFor="name"
            />

            <InputFields
              fieldName="name"
              type="text"
              placeholder="Enter student name"
              label="Class Name (*)"
              htmlFor="name"
            />

            <SelectFields
              fieldName="industryGroupId"
              htmlFor="industry"
              label="Industry (*)"
              initialValue=""
            >
              <option value="">Choose your industry</option>

              {fetchAllIndustries.data &&
                fetchAllIndustries.data.map((industry) => (
                  <option key={industry.id} value={industry.id}>
                    {industry.name}
                  </option>
                ))}
            </SelectFields>
          </Modal>
        </FormProvider>
      )}
    </>
  );
};

export default ModalUpdateFormClasses;
