import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { API_BE_SIDE } from "../../link_api";

const authApis = createApi({
  reducerPath: "auths",
  baseQuery: fetchBaseQuery({
    baseUrl: API_BE_SIDE,
  }),
  endpoints: (builder) => {
    return {
      login: builder.mutation({
        query: (data) => {
          return {
            url: "/auth/login",
            method: "POST",
            body: data,
          };
        },
      }),
      register: builder.mutation({
        query: (data) => {
          return {
            url: "/auth/sign-up",
            method: "POST",
            body: data,
          };
        },
      }),
    };
  },
});

export const { useLoginMutation, useRegisterMutation } = authApis;
export { authApis };
