import { FormProvider, useForm } from "react-hook-form";
import { ButtonFields, InputFields } from "../../../shared/FormElement";
import { VALIDATOR_REQUIRED } from "../../../shared/util/validators";
import { Modal } from "../../../shared/components";
import { toast } from "react-toastify";
import { formatDate } from "../../../utils/format-date.js";
import { useAddCourseMutation } from "../../../redux/apis/course/course.api";

const ModalCreateFormStudent = ({ show = false, toggleModal }) => {
  const methods = useForm({ mode: "all" });

  const [addNewCourse, addNewCourseResults] = useAddCourseMutation();

  const onSubmit = (data) => {
    const formattedData = {
      ...data,
      dateBirth: formatDate(data.dateBirth),
    };
    addNewCourse(formattedData)
      .unwrap()
      .then(() => {
        toast.success("Added new student successfully!", {
          autoClose: 2000,
        });
        toggleModal();
      })
      .catch((error) => {
        toast.error(error.data.message);
      });
  };

  return (
    <>
      <FormProvider {...methods}>
        <Modal
          onSubmit={methods.handleSubmit(onSubmit)}
          show={show}
          onCancel={toggleModal}
          footer={
            <div className="d-flex flex-row-reverse aligns-item">
              <ButtonFields
                type="submit"
                primary
                className="ml-5"
                isLoading={addNewCourseResults.isLoading}
              >
                SUBMIT
              </ButtonFields>
              <ButtonFields type="button" onClick={toggleModal} borderOnly>
                BACK
              </ButtonFields>
            </div>
          }
        >
          <InputFields
            fieldName="name"
            validators={[VALIDATOR_REQUIRED("Course Name cannot be empty")]}
            type="text"
            placeholder="Enter Course Name"
            label="Course Name (*)"
            htmlFor="name"
          />
        </Modal>
      </FormProvider>
    </>
  );
};

export default ModalCreateFormStudent;
