import { MainComponentAdmin } from "../../components";
import ManagementIndustryGroup from "../../components/IndustryGroup/ManagementIndustryGroup";

const IndustryGroupPage = () => {
  return (
    <MainComponentAdmin>
      <ManagementIndustryGroup />
    </MainComponentAdmin>
  );
};

export default IndustryGroupPage;
